import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFirebaseErrorMessage } from "@utils/errorMessage";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Card, Form, Spin, Input, Image, Button, message } from "antd";
import { HOTEL_LOGO_URL, PRIMARY_COLOR } from "@constants/globalConstants";

const primaryColor = PRIMARY_COLOR;

const Login: React.FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onFinish = async (userData: any) => {
    const { username, password } = userData;
    try {
      setLoading(true);
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, username, password);
    } catch (error: any) {
      message.error(getFirebaseErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <div className="login">
        <Card className="login-card" title={null}>
          <div
            style={{ textAlign: "center", paddingTop: 20, paddingBottom: 40 }}
          >
            <Image
              width="60%"
              height="60%"
              preview={false}
              src={HOTEL_LOGO_URL}
            />
          </div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            size={"large"}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Correo no es valido.",
                },
              ]}
            >
              <Input
                className="input-class"
                placeholder="Correo electrónico"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: "Contraseña no es valida." }]}
            >
              <Input.Password
                className="input-class"
                placeholder="Contraseña"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="link"
                size={"small"}
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: primaryColor,
                  fontWeight: "bold",
                }}
                onClick={() => navigate("reset-password", { state: { email } })}
              >
                ¿Olvidó su contraseña?
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Iniciar sesión
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Spin>
  );
};

export default Login;
