import React, { useEffect, useState } from "react";
import { ICheckinProps } from "./table";
import { isUserAdmin } from "@utils/user";
import { getUser } from "@services/userService";
import { getErrorMessage } from "@utils/errorMessage";
import { getCompany } from "@services/companyService";
import { ClockCircleTwoTone } from "@ant-design/icons";
import { Modal, Button, Tooltip, message } from "antd";
import { getModalHeader } from "@components/Common/Render";

import {
  checkinReservation,
  checkoutReservation,
  completeReservation,
} from "@services/reservationService";

import UserForm from "@pages/Users/UserForm";
import CompanyForm from "@pages/Companies/CompanyForm";

const CheckinComponent: React.FC<ICheckinProps> = (props) => {
  const [user, setUser] = useState<any>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [company, setCompany] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [checkinOpen, setCheckinOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [completedOpen, setCompletedOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);

  const reservation = props.dataObject;

  useEffect(() => {
    checkIfUserIsAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfUserIsAdmin = async () => {
    const isAdmin = await isUserAdmin();
    setIsAdmin(isAdmin);
  };

  const onCheckin = async () => {
    try {
      setLoading(true);
      await checkinReservation(reservation.id);
      setCheckinOpen(false);
      setUserModalOpen(false);
      setCompanyModalOpen(false);
      message.success("Checkin realizado exitosamente!");
      props.onReload();
    } catch (error: any) {
      setCheckinOpen(false);
      setUserModalOpen(false);
      setCompanyModalOpen(false);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onCheckout = async () => {
    try {
      setLoading(true);
      await checkoutReservation(reservation.id);
      setCheckoutOpen(false);
      message.success("Checkout realizado exitosamente!");
      props.onReload();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onCompleted = async () => {
    try {
      setLoading(true);
      await completeReservation(reservation.id);
      setCompletedOpen(false);
      message.success("Reservación completada exitosamente!");
      props.onReload();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const openCheckinModal = async () => {
    try {
      setLoading(true);
      const user = await getUser(reservation.userId);
      setUser(user);
      setCheckinOpen(true);
      setUserModalOpen(true);

      if (reservation.companyId) {
        const company = await getCompany(reservation.companyId);
        setCompany(company);
        setCompanyModalOpen(true);
      }
    } catch (error) {
      message.error(getErrorMessage(error));
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!reservation.checkinAt && (
        <Tooltip title="Checkin">
          <Button
            ghost
            loading={loading}
            className="ghost-button"
            icon={
              <ClockCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => openCheckinModal()}
              />
            }
          />
        </Tooltip>
      )}
      {reservation.checkinAt && !reservation.checkoutAt && (
        <Tooltip title="Checkout">
          <Button
            ghost
            loading={loading}
            className="ghost-button"
            icon={
              <ClockCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => setCheckoutOpen(true)}
              />
            }
          />
        </Tooltip>
      )}
      {reservation.checkinAt && reservation.checkoutAt && isAdmin && (
        <Tooltip title="Completado">
          <Button
            ghost
            loading={loading}
            className="ghost-button"
            icon={<ClockCircleTwoTone onClick={() => setCompletedOpen(true)} />}
          />
        </Tooltip>
      )}

      {checkinOpen && (
        <Modal
          centered
          width={400}
          okText="Checkin"
          open={checkinOpen}
          onOk={() => onCheckin()}
          onCancel={() => setCheckinOpen(false)}
          title={getModalHeader("Desea realizar checkin?")}
        ></Modal>
      )}

      {checkoutOpen && (
        <Modal
          centered
          width={400}
          okText="Checkout"
          open={checkoutOpen}
          onOk={() => onCheckout()}
          onCancel={() => setCheckoutOpen(false)}
          title={getModalHeader("Desea realizar checkout?")}
        ></Modal>
      )}

      {completedOpen && (
        <Modal
          centered
          width={500}
          okText="Completar"
          open={completedOpen}
          onOk={() => onCompleted()}
          onCancel={() => setCompletedOpen(false)}
          title={getModalHeader("Desea completar la reservación?")}
        ></Modal>
      )}

      {userModalOpen && (
        <Modal
          title="Actualizar Cliente"
          open={userModalOpen}
          centered
          width={650}
          footer={[]}
          onCancel={() => setUserModalOpen(false)}
        >
          <UserForm
            dataObject={user}
            excludeFields={false}
            onReload={() => setUserModalOpen(false)}
            onCancel={() => setUserModalOpen(false)}
          />
        </Modal>
      )}

      {companyModalOpen && (
        <Modal
          title="Actualizar Empresa"
          open={companyModalOpen}
          centered
          width={650}
          footer={[]}
          onCancel={() => setCompanyModalOpen(false)}
        >
          <CompanyForm
            dataObject={company}
            excludeFields={false}
            onReload={() => setCompanyModalOpen(false)}
            onCancel={() => setCompanyModalOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default CheckinComponent;
