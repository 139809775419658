import React from "react";
import { useDroppable } from "@dnd-kit/core";

export function Droppable(props: any) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: { ...props.data },
  });
  const style = {
    height: "30px",
    opacity: 0.2,
    backgroundColor: isOver ? "Gainsboro" : "",
    borderStyle: isOver ? "dashed" : "",
  };

  return (
    <div ref={setNodeRef} style={style} className="droppable">
      {props.children}
    </div>
  );
}
