import axios from "axios";
import IReservation from "@interfaces/IReservation";

import { Moment } from "moment";
import { getConfigHeaders } from "@constants/axiosConfig";
import { getMonthString, getYearString } from "@utils/date";

const API_BASE_URL = "/api/reservation";

export const getReservation = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation:", error);
    throw error;
  }
};

export const getActiveReservations = async (filteredDate: Moment) => {
  try {
    const month = getMonthString(filteredDate);
    const year = getYearString(filteredDate);
    const headers = await getConfigHeaders();
    const response = await axios.get(
      `${API_BASE_URL}/active/${month}/${year}`,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching active reservations:", error);
    throw error;
  }
};

export const getInactiveReservations = async (filteredDate: Moment) => {
  try {
    const month = getMonthString(filteredDate);
    const year = getYearString(filteredDate);
    const headers = await getConfigHeaders();
    const response = await axios.get(
      `${API_BASE_URL}/inactive/${month}/${year}`,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching inactive reservations:", error);
    throw error;
  }
};

export const getReservationDetailsByReservationId = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/detail/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation details:", error);
    throw error;
  }
};

export const getReservationProductsByReservationId = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/product/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation products:", error);
    throw error;
  }
};

export const getReservationServicesByReservationId = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/service/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation services:", error);
    throw error;
  }
};

export const getReservationRoomsPaymentsByReservationId = async (
  id: string
) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(
      `${API_BASE_URL}/room/payments/${id}`,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation rooms payments:", error);
    throw error;
  }
};

export const createReservation = async (reservationData: IReservation) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      reservationData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error creating reservation:", error);
    throw error;
  }
};

export const updateReservation = async (reservationData: IReservation) => {
  try {
    const { id } = reservationData;
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/${id}`,
      reservationData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error updating reservation:", error);
    throw error;
  }
};

export const checkinReservation = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/checkin/${id}`,
      {},
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error completing checkin:", error);
    throw error;
  }
};

export const checkoutReservation = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/checkout/${id}`,
      {},
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error completing checkout:", error);
    throw error;
  }
};

export const completeReservation = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/complete/${id}`,
      {},
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error completing reservation:", error);
    throw error;
  }
};

export const deleteReservation = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error deleting reservation:", error);
    throw error;
  }
};
