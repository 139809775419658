export const codes = [
  {
    name: "Afghanistan",
    value: "+93",
    label: "AF",
  },
  {
    name: "Aland Islands",
    value: "+358",
    label: "AX",
  },
  {
    name: "Albania",
    value: "+355",
    label: "AL",
  },
  {
    name: "Algeria",
    value: "+213",
    label: "DZ",
  },
  {
    name: "AmericanSamoa",
    value: "+1684",
    label: "AS",
  },
  {
    name: "Andorra",
    value: "+376",
    label: "AD",
  },
  {
    name: "Angola",
    value: "+244",
    label: "AO",
  },
  {
    name: "Anguilla",
    value: "+1264",
    label: "AI",
  },
  {
    name: "Antarctica",
    value: "+672",
    label: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    value: "+1268",
    label: "AG",
  },
  {
    name: "Argentina",
    value: "+54",
    label: "AR",
  },
  {
    name: "Armenia",
    value: "+374",
    label: "AM",
  },
  {
    name: "Aruba",
    value: "+297",
    label: "AW",
  },
  {
    name: "Australia",
    value: "+61",
    label: "AU",
  },
  {
    name: "Austria",
    value: "+43",
    label: "AT",
  },
  {
    name: "Azerbaijan",
    value: "+994",
    label: "AZ",
  },
  {
    name: "Bahamas",
    value: "+1242",
    label: "BS",
  },
  {
    name: "Bahrain",
    value: "+973",
    label: "BH",
  },
  {
    name: "Bangladesh",
    value: "+880",
    label: "BD",
  },
  {
    name: "Barbados",
    value: "+1246",
    label: "BB",
  },
  {
    name: "Belarus",
    value: "+375",
    label: "BY",
  },
  {
    name: "Belgium",
    value: "+32",
    label: "BE",
  },
  {
    name: "Belize",
    value: "+501",
    label: "BZ",
  },
  {
    name: "Benin",
    value: "+229",
    label: "BJ",
  },
  {
    name: "Bermuda",
    value: "+1441",
    label: "BM",
  },
  {
    name: "Bhutan",
    value: "+975",
    label: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    value: "+591",
    label: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    value: "+387",
    label: "BA",
  },
  {
    name: "Botswana",
    value: "+267",
    label: "BW",
  },
  {
    name: "Brazil",
    value: "+55",
    label: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    value: "+246",
    label: "IO",
  },
  {
    name: "Brunei Darussalam",
    value: "+673",
    label: "BN",
  },
  {
    name: "Bulgaria",
    value: "+359",
    label: "BG",
  },
  {
    name: "Burkina Faso",
    value: "+226",
    label: "BF",
  },
  {
    name: "Burundi",
    value: "+257",
    label: "BI",
  },
  {
    name: "Cambodia",
    value: "+855",
    label: "KH",
  },
  {
    name: "Cameroon",
    value: "+237",
    label: "CM",
  },
  {
    name: "Canada",
    value: "+1",
    label: "CA",
  },
  {
    name: "Cape Verde",
    value: "+238",
    label: "CV",
  },
  {
    name: "Cayman Islands",
    value: "+ 345",
    label: "KY",
  },
  {
    name: "Central African Republic",
    value: "+236",
    label: "CF",
  },
  {
    name: "Chad",
    value: "+235",
    label: "TD",
  },
  {
    name: "Chile",
    value: "+56",
    label: "CL",
  },
  {
    name: "China",
    value: "+86",
    label: "CN",
  },
  {
    name: "Christmas Island",
    value: "+61",
    label: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    value: "+61",
    label: "CC",
  },
  {
    name: "Colombia",
    value: "+57",
    label: "CO",
  },
  {
    name: "Comoros",
    value: "+269",
    label: "KM",
  },
  {
    name: "Congo",
    value: "+242",
    label: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    value: "+243",
    label: "CD",
  },
  {
    name: "Cook Islands",
    value: "+682",
    label: "CK",
  },
  {
    name: "Costa Rica",
    value: "+506",
    label: "CR",
  },
  {
    name: "Cote d'Ivoire",
    value: "+225",
    label: "CI",
  },
  {
    name: "Croatia",
    value: "+385",
    label: "HR",
  },
  {
    name: "Cuba",
    value: "+53",
    label: "CU",
  },
  {
    name: "Cyprus",
    value: "+357",
    label: "CY",
  },
  {
    name: "Czech Republic",
    value: "+420",
    label: "CZ",
  },
  {
    name: "Denmark",
    value: "+45",
    label: "DK",
  },
  {
    name: "Djibouti",
    value: "+253",
    label: "DJ",
  },
  {
    name: "Dominica",
    value: "+1767",
    label: "DM",
  },
  {
    name: "Dominican Republic",
    value: "+1849",
    label: "DO",
  },
  {
    name: "Ecuador",
    value: "+593",
    label: "EC",
  },
  {
    name: "Egypt",
    value: "+20",
    label: "EG",
  },
  {
    name: "El Salvador",
    value: "+503",
    label: "SV",
  },
  {
    name: "Equatorial Guinea",
    value: "+240",
    label: "GQ",
  },
  {
    name: "Eritrea",
    value: "+291",
    label: "ER",
  },
  {
    name: "Estonia",
    value: "+372",
    label: "EE",
  },
  {
    name: "Ethiopia",
    value: "+251",
    label: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    value: "+500",
    label: "FK",
  },
  {
    name: "Faroe Islands",
    value: "+298",
    label: "FO",
  },
  {
    name: "Fiji",
    value: "+679",
    label: "FJ",
  },
  {
    name: "Finland",
    value: "+358",
    label: "FI",
  },
  {
    name: "France",
    value: "+33",
    label: "FR",
  },
  {
    name: "French Guiana",
    value: "+594",
    label: "GF",
  },
  {
    name: "French Polynesia",
    value: "+689",
    label: "PF",
  },
  {
    name: "Gabon",
    value: "+241",
    label: "GA",
  },
  {
    name: "Gambia",
    value: "+220",
    label: "GM",
  },
  {
    name: "Georgia",
    value: "+995",
    label: "GE",
  },
  {
    name: "Germany",
    value: "+49",
    label: "DE",
  },
  {
    name: "Ghana",
    value: "+233",
    label: "GH",
  },
  {
    name: "Gibraltar",
    value: "+350",
    label: "GI",
  },
  {
    name: "Greece",
    value: "+30",
    label: "GR",
  },
  {
    name: "Greenland",
    value: "+299",
    label: "GL",
  },
  {
    name: "Grenada",
    value: "+1473",
    label: "GD",
  },
  {
    name: "Guadeloupe",
    value: "+590",
    label: "GP",
  },
  {
    name: "Guam",
    value: "+1671",
    label: "GU",
  },
  {
    name: "Guatemala",
    value: "+502",
    label: "GT",
  },
  {
    name: "Guernsey",
    value: "+44",
    label: "GG",
  },
  {
    name: "Guinea",
    value: "+224",
    label: "GN",
  },
  {
    name: "Guinea-Bissau",
    value: "+245",
    label: "GW",
  },
  {
    name: "Guyana",
    value: "+595",
    label: "GY",
  },
  {
    name: "Haiti",
    value: "+509",
    label: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    value: "+379",
    label: "VA",
  },
  {
    name: "Honduras",
    value: "+504",
    label: "HN",
  },
  {
    name: "Hong Kong",
    value: "+852",
    label: "HK",
  },
  {
    name: "Hungary",
    value: "+36",
    label: "HU",
  },
  {
    name: "Iceland",
    value: "+354",
    label: "IS",
  },
  {
    name: "India",
    value: "+91",
    label: "IN",
  },
  {
    name: "Indonesia",
    value: "+62",
    label: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    value: "+98",
    label: "IR",
  },
  {
    name: "Iraq",
    value: "+964",
    label: "IQ",
  },
  {
    name: "Ireland",
    value: "+353",
    label: "IE",
  },
  {
    name: "Isle of Man",
    value: "+44",
    label: "IM",
  },
  {
    name: "Israel",
    value: "+972",
    label: "IL",
  },
  {
    name: "Italy",
    value: "+39",
    label: "IT",
  },
  {
    name: "Jamaica",
    value: "+1876",
    label: "JM",
  },
  {
    name: "Japan",
    value: "+81",
    label: "JP",
  },
  {
    name: "Jersey",
    value: "+44",
    label: "JE",
  },
  {
    name: "Jordan",
    value: "+962",
    label: "JO",
  },
  {
    name: "Kazakhstan",
    value: "+77",
    label: "KZ",
  },
  {
    name: "Kenya",
    value: "+254",
    label: "KE",
  },
  {
    name: "Kiribati",
    value: "+686",
    label: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    value: "+850",
    label: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    value: "+82",
    label: "KR",
  },
  {
    name: "Kuwait",
    value: "+965",
    label: "KW",
  },
  {
    name: "Kyrgyzstan",
    value: "+996",
    label: "KG",
  },
  {
    name: "Laos",
    value: "+856",
    label: "LA",
  },
  {
    name: "Latvia",
    value: "+371",
    label: "LV",
  },
  {
    name: "Lebanon",
    value: "+961",
    label: "LB",
  },
  {
    name: "Lesotho",
    value: "+266",
    label: "LS",
  },
  {
    name: "Liberia",
    value: "+231",
    label: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    value: "+218",
    label: "LY",
  },
  {
    name: "Liechtenstein",
    value: "+423",
    label: "LI",
  },
  {
    name: "Lithuania",
    value: "+370",
    label: "LT",
  },
  {
    name: "Luxembourg",
    value: "+352",
    label: "LU",
  },
  {
    name: "Macao",
    value: "+853",
    label: "MO",
  },
  {
    name: "Macedonia",
    value: "+389",
    label: "MK",
  },
  {
    name: "Madagascar",
    value: "+261",
    label: "MG",
  },
  {
    name: "Malawi",
    value: "+265",
    label: "MW",
  },
  {
    name: "Malaysia",
    value: "+60",
    label: "MY",
  },
  {
    name: "Maldives",
    value: "+960",
    label: "MV",
  },
  {
    name: "Mali",
    value: "+223",
    label: "ML",
  },
  {
    name: "Malta",
    value: "+356",
    label: "MT",
  },
  {
    name: "Marshall Islands",
    value: "+692",
    label: "MH",
  },
  {
    name: "Martinique",
    value: "+596",
    label: "MQ",
  },
  {
    name: "Mauritania",
    value: "+222",
    label: "MR",
  },
  {
    name: "Mauritius",
    value: "+230",
    label: "MU",
  },
  {
    name: "Mayotte",
    value: "+262",
    label: "YT",
  },
  {
    name: "Mexico",
    value: "+52",
    label: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    value: "+691",
    label: "FM",
  },
  {
    name: "Moldova",
    value: "+373",
    label: "MD",
  },
  {
    name: "Monaco",
    value: "+377",
    label: "MC",
  },
  {
    name: "Mongolia",
    value: "+976",
    label: "MN",
  },
  {
    name: "Montenegro",
    value: "+382",
    label: "ME",
  },
  {
    name: "Montserrat",
    value: "+1664",
    label: "MS",
  },
  {
    name: "Morocco",
    value: "+212",
    label: "MA",
  },
  {
    name: "Mozambique",
    value: "+258",
    label: "MZ",
  },
  {
    name: "Myanmar",
    value: "+95",
    label: "MM",
  },
  {
    name: "Namibia",
    value: "+264",
    label: "NA",
  },
  {
    name: "Nauru",
    value: "+674",
    label: "NR",
  },
  {
    name: "Nepal",
    value: "+977",
    label: "NP",
  },
  {
    name: "Netherlands",
    value: "+31",
    label: "NL",
  },
  {
    name: "Netherlands Antilles",
    value: "+599",
    label: "AN",
  },
  {
    name: "New Caledonia",
    value: "+687",
    label: "NC",
  },
  {
    name: "New Zealand",
    value: "+64",
    label: "NZ",
  },
  {
    name: "Nicaragua",
    value: "+505",
    label: "NI",
  },
  {
    name: "Niger",
    value: "+227",
    label: "NE",
  },
  {
    name: "Nigeria",
    value: "+234",
    label: "NG",
  },
  {
    name: "Niue",
    value: "+683",
    label: "NU",
  },
  {
    name: "Norfolk Island",
    value: "+672",
    label: "NF",
  },
  {
    name: "Northern Mariana Islands",
    value: "+1670",
    label: "MP",
  },
  {
    name: "Norway",
    value: "+47",
    label: "NO",
  },
  {
    name: "Oman",
    value: "+968",
    label: "OM",
  },
  {
    name: "Pakistan",
    value: "+92",
    label: "PK",
  },
  {
    name: "Palau",
    value: "+680",
    label: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    value: "+970",
    label: "PS",
  },
  {
    name: "Panama",
    value: "+507",
    label: "PA",
  },
  {
    name: "Papua New Guinea",
    value: "+675",
    label: "PG",
  },
  {
    name: "Paraguay",
    value: "+595",
    label: "PY",
  },
  {
    name: "Peru",
    value: "+51",
    label: "PE",
  },
  {
    name: "Philippines",
    value: "+63",
    label: "PH",
  },
  {
    name: "Pitcairn",
    value: "+872",
    label: "PN",
  },
  {
    name: "Poland",
    value: "+48",
    label: "PL",
  },
  {
    name: "Portugal",
    value: "+351",
    label: "PT",
  },
  {
    name: "Puerto Rico",
    value: "+1939",
    label: "PR",
  },
  {
    name: "Qatar",
    value: "+974",
    label: "QA",
  },
  {
    name: "Romania",
    value: "+40",
    label: "RO",
  },
  {
    name: "Russia",
    value: "+7",
    label: "RU",
  },
  {
    name: "Rwanda",
    value: "+250",
    label: "RW",
  },
  {
    name: "Reunion",
    value: "+262",
    label: "RE",
  },
  {
    name: "Saint Barthelemy",
    value: "+590",
    label: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    value: "+290",
    label: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    value: "+1869",
    label: "KN",
  },
  {
    name: "Saint Lucia",
    value: "+1758",
    label: "LC",
  },
  {
    name: "Saint Martin",
    value: "+590",
    label: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    value: "+508",
    label: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    value: "+1784",
    label: "VC",
  },
  {
    name: "Samoa",
    value: "+685",
    label: "WS",
  },
  {
    name: "San Marino",
    value: "+378",
    label: "SM",
  },
  {
    name: "Sao Tome and Principe",
    value: "+239",
    label: "ST",
  },
  {
    name: "Saudi Arabia",
    value: "+966",
    label: "SA",
  },
  {
    name: "Senegal",
    value: "+221",
    label: "SN",
  },
  {
    name: "Serbia",
    value: "+381",
    label: "RS",
  },
  {
    name: "Seychelles",
    value: "+248",
    label: "SC",
  },
  {
    name: "Sierra Leone",
    value: "+232",
    label: "SL",
  },
  {
    name: "Singapore",
    value: "+65",
    label: "SG",
  },
  {
    name: "Slovakia",
    value: "+421",
    label: "SK",
  },
  {
    name: "Slovenia",
    value: "+386",
    label: "SI",
  },
  {
    name: "Solomon Islands",
    value: "+677",
    label: "SB",
  },
  {
    name: "Somalia",
    value: "+252",
    label: "SO",
  },
  {
    name: "South Africa",
    value: "+27",
    label: "ZA",
  },
  {
    name: "South Sudan",
    value: "+211",
    label: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    value: "+500",
    label: "GS",
  },
  {
    name: "Spain",
    value: "+34",
    label: "ES",
  },
  {
    name: "Sri Lanka",
    value: "+94",
    label: "LK",
  },
  {
    name: "Sudan",
    value: "+249",
    label: "SD",
  },
  {
    name: "Suriname",
    value: "+597",
    label: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    value: "+47",
    label: "SJ",
  },
  {
    name: "Swaziland",
    value: "+268",
    label: "SZ",
  },
  {
    name: "Sweden",
    value: "+46",
    label: "SE",
  },
  {
    name: "Switzerland",
    value: "+41",
    label: "CH",
  },
  {
    name: "Syrian Arab Republic",
    value: "+963",
    label: "SY",
  },
  {
    name: "Taiwan",
    value: "+886",
    label: "TW",
  },
  {
    name: "Tajikistan",
    value: "+992",
    label: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    value: "+255",
    label: "TZ",
  },
  {
    name: "Thailand",
    value: "+66",
    label: "TH",
  },
  {
    name: "Timor-Leste",
    value: "+670",
    label: "TL",
  },
  {
    name: "Togo",
    value: "+228",
    label: "TG",
  },
  {
    name: "Tokelau",
    value: "+690",
    label: "TK",
  },
  {
    name: "Tonga",
    value: "+676",
    label: "TO",
  },
  {
    name: "Trinidad and Tobago",
    value: "+1868",
    label: "TT",
  },
  {
    name: "Tunisia",
    value: "+216",
    label: "TN",
  },
  {
    name: "Turkey",
    value: "+90",
    label: "TR",
  },
  {
    name: "Turkmenistan",
    value: "+993",
    label: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    value: "+1649",
    label: "TC",
  },
  {
    name: "Tuvalu",
    value: "+688",
    label: "TV",
  },
  {
    name: "Uganda",
    value: "+256",
    label: "UG",
  },
  {
    name: "Ukraine",
    value: "+380",
    label: "UA",
  },
  {
    name: "United Arab Emirates",
    value: "+971",
    label: "AE",
  },
  {
    name: "United Kingdom",
    value: "+44",
    label: "GB",
  },
  {
    name: "United States",
    value: "+1",
    label: "US",
  },
  {
    name: "Uruguay",
    value: "+598",
    label: "UY",
  },
  {
    name: "Uzbekistan",
    value: "+998",
    label: "UZ",
  },
  {
    name: "Vanuatu",
    value: "+678",
    label: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    value: "+58",
    label: "VE",
  },
  {
    name: "Vietnam",
    value: "+84",
    label: "VN",
  },
  {
    name: "Virgin Islands, British",
    value: "+1284",
    label: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    value: "+1340",
    label: "VI",
  },
  {
    name: "Wallis and Futuna",
    value: "+681",
    label: "WF",
  },
  {
    name: "Yemen",
    value: "+967",
    label: "YE",
  },
  {
    name: "Zambia",
    value: "+260",
    label: "ZM",
  },
  {
    name: "Zimbabwe",
    value: "+263",
    label: "ZW",
  },
];
