import * as React from 'react';
import type { Moment } from 'moment';
import { DatePicker } from 'antd';
import type { PickerProps } from 'antd/es/date-picker/generatePicker';

export interface TimePickerProps extends Omit<PickerProps<Moment>, 'picker'> {}

const TimePicker = React.forwardRef<any, TimePickerProps>((props: any, ref) => (
  <DatePicker {...props} picker="time" ref={ref} />
));

TimePicker.displayName = 'TimePicker';

export default TimePicker;