import React, { Key, useEffect, useState } from "react";

import { Moment } from "moment";
import { connectAndPrint } from "@utils/printer";
import { getErrorMessage } from "@utils/errorMessage";
import { SorterResult } from "antd/es/table/interface";
import type { ColumnsType, TableProps } from "antd/es/table";
import { StopOutlined, PrinterOutlined } from "@ant-design/icons";
import { getDateDifference, getDateString, getToday } from "@utils/date";
import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { Row, Col, Card, Table, Layout, message, Tooltip, Button } from "antd";
import { getCurrencyFormat, isFilterMatched, sortAlphaNum } from "@utils/util";

import {
  getInvoices,
  deleteInvoice,
  getInvoiceDetailsById,
} from "@services/invoiceService";

import IInvoice from "@interfaces/IInvoice";
import DateToolbar from "@components/Table/DateToolbar";
import TableToolbar from "@components/Table/TableToolbar";
import IReservationProduct from "@interfaces/IReservationProduct";
import IReservationService from "@interfaces/IReservationService";
import IReservationRoomsPayment from "@interfaces/IReservationRoomsPayment";

const { Header, Content } = Layout;

const InvoiceHistory: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IInvoice[]>([]);
  const [filteredString, setFilteredString] = useState<Key>("");
  const [selectedRowKey, setSelectedRowKey] = useState<any>([]);
  const [filteredDate, setFilteredDate] = useState<any>(getToday());
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IInvoice>>({});

  const [selectedRowProducts, setSelectedRowProducts] = useState<
    IReservationProduct[]
  >([]);
  const [selectedRowServices, setSelectedRowServices] = useState<
    IReservationService[]
  >([]);
  const [selectedRowRoomsPayments, setSelectedRowRoomsPayments] = useState<
    IReservationRoomsPayment[]
  >([]);

  useEffect(() => {
    fetchData(filteredDate);
  }, [filteredDate]);

  const fetchData = async (filteredDate: Moment) => {
    try {
      setLoading(true);
      const invoicesData = await getInvoices(filteredDate);
      setData(invoicesData);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<IInvoice> = [
    {
      key: "createdAt",
      title: "Fecha",
      dataIndex: "createdAt",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => getDateDifference(a.createdAt, b.createdAt),
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      render: (createdAt) => getDateString(createdAt),
    },
    {
      key: "invoiceClientName",
      title: "Cliente",
      dataIndex: "invoiceClientName",
      sorter: (a, b) => sortAlphaNum(a.invoiceClientName, b.invoiceClientName),
      sortOrder:
        sortedInfo.columnKey === "invoiceClientName" ? sortedInfo.order : null,
    },
    {
      key: "paymentMethod",
      title: "Método de pago",
      dataIndex: "paymentMethod",
      sorter: (a, b) => sortAlphaNum(a.paymentMethod, b.paymentMethod),
      sortOrder:
        sortedInfo.columnKey === "paymentMethod" ? sortedInfo.order : null,
    },
    {
      key: "invoiceName",
      title: "Tipo",
      dataIndex: "invoiceName",
      sorter: (a, b) => sortAlphaNum(a.invoiceName, b.invoiceName),
      sortOrder:
        sortedInfo.columnKey === "invoiceName" ? sortedInfo.order : null,
    },
    {
      key: "createdBy",
      title: "Cajero",
      dataIndex: "createdBy",
      sorter: (a, b) => sortAlphaNum(a.createdBy, b.createdBy),
      sortOrder: sortedInfo.columnKey === "createdBy" ? sortedInfo.order : null,
    },
    {
      key: "total",
      title: "Total",
      dataIndex: "total",
      sorter: (a, b) => a.total - b.total,
      sortOrder: sortedInfo.columnKey === "total" ? sortedInfo.order : null,
      render: (total: number) => `L. ${getCurrencyFormat(total)}`,
    },
    {
      key: "x",
      dataIndex: "",
      title: "Acción",
      align: "center",
      width: 100,
      render: (invoice: IInvoice) => (
        <>
          <Tooltip title="Imprimir">
            <Button
              ghost
              className="ghost-button"
              icon={<PrinterOutlined onClick={() => onPrint(invoice)} />}
            />
          </Tooltip>
          {invoice.invoiceName !== "Caja 1" && (
            <Tooltip title="Anular">
              <Button
                ghost
                className="ghost-button"
                icon={<StopOutlined onClick={() => onDelete(invoice)} />}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const onPrint = async (invoice: IInvoice) => {
    try {
      if (invoice) {
        await connectAndPrint([invoice]);
      }
    } catch (error: any) {
      message.error(getErrorMessage(error));
    }
  };

  const onDelete = async (invoice: IInvoice) => {
    try {
      setLoading(true);
      await deleteInvoice(invoice.id);
      await fetchData(filteredDate);
      message.success("Factura borrada exitosamente!");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange: TableProps<IInvoice>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IInvoice>);
  };

  const expandableComponent = {
    expandedRowKeys: selectedRowKey,
    onExpand: async (expanded: boolean, record: any) => {
      if (expanded) {
        const { products, services, roomsPayments } =
          await getInvoiceDetailsById(record.id);
        setSelectedRowProducts(products);
        setSelectedRowServices(services);
        setSelectedRowRoomsPayments(roomsPayments);
        setSelectedRowKey([record.id]);
      } else {
        setSelectedRowKey([]);
      }
    },
    expandedRowRender: (record: any) => (
      <>
        <Card className="mb" title="Detalles">
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Factura Autorizada </b>
            <br />
            <span>{record.invoiceAuthorizedNumber}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>C.A.I. </b>
            <br />
            <span>{record.invoiceCai}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Notas </b>
            <br />
            <span>{record.invoiceNotes}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail"></Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>D.N.I. Cliente </b>
            <br />
            <span>{record.invoiceClientDni}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>R.T.N. Cliente </b>
            <br />
            <span>{record.invoiceClientRtn}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Nombre Empresa </b>
            <br />
            <span>{record.invoiceCompanyName}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>R.T.N. Empresa </b>
            <br />
            <span>{record.invoiceCompanyRtn}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Subtotal </b>
            <br />
            <span>L. {getCurrencyFormat(record.subtotal)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Descuento </b>
            <br />
            <span>L. {getCurrencyFormat(record.discount)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>I.S.V. </b>
            <br />
            <span>L. {getCurrencyFormat(record.isvTax)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>I.S.R.T. </b>
            <br />
            <span>L. {getCurrencyFormat(record.isrtTax)}</span>
          </Card.Grid>
        </Card>
        {[
          ...selectedRowProducts,
          ...selectedRowServices,
          ...selectedRowRoomsPayments,
        ].length > 0 && (
          <Card className="mb" title="Pagos">
            {[
              ...selectedRowProducts,
              ...selectedRowServices,
              ...selectedRowRoomsPayments,
            ]?.map((payment: any) => (
              <Card.Grid
                key={payment.id}
                hoverable={false}
                className="table-row-detail"
              >
                <b>{payment.name}</b>
                <br />
                <b>Precio:</b> <span>L.{getCurrencyFormat(payment.price)}</span>
                <br />
                {payment.date ? (
                  <>
                    <span>{getDateString(payment.date)}</span>
                    <br />
                  </>
                ) : (
                  <>
                    <b>Cantidad:</b> <span>{payment.quantity}</span>
                    <br />
                  </>
                )}
                <br />
                {payment.invoiceIds ? (
                  <CheckCircleFilled
                    style={{
                      color: "green",
                      fontSize: "32px",
                    }}
                  />
                ) : (
                  <MinusCircleFilled
                    style={{
                      color: "red",
                      fontSize: "32px",
                    }}
                  />
                )}
              </Card.Grid>
            ))}
          </Card>
        )}
        <Card className="mb" title="Historial de Cambios">
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Creado Por </b>
            <br />
            <span>{record.createdBy}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Modificado Por </b>
            <br />
            <span>{record.modifiedBy}</span>
          </Card.Grid>
        </Card>
      </>
    ),
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <Row>
            <Col style={{ display: "flex" }} flex={0.5}>
              <DateToolbar
                filteredDate={filteredDate}
                setFilteredDate={setFilteredDate}
              />
            </Col>
            <Col style={{ display: "flex", flexDirection: "column" }} flex={12}>
              <TableToolbar
                onFilterChange={setFilteredString}
                canCreate={false}
              />
            </Col>
          </Row>
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            pagination={{ pageSize: 20 }}
            expandable={expandableComponent}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default InvoiceHistory;
