export const breadcrumbNameMap: Record<string, string> = {
  "/": "Home",
  "/timeline": "",
  "/reservations": "Reservaciones",
  "/reservations/form": "reservación",
  "/reservations/reservationInvoice": "Pagos de reservación",
  "/invoicesHistory": "Historial de facturas",
  "/reservationsHistory": "Historial de reservaciones",
  "/rooms": "Habitaciones",
  "/rooms/form": "habitación",
  "/products": "Productos",
  "/products/form": "producto",
  "/services": "Servicios",
  "/services/form": "servicio",
  "/users": "Usuarios",
  "/users/form": "usuario",
  "/companies": "Empresas",
  "/companies/form": "empresa",
  "/cashRegisterClosingReport": "Reporte de Cierre de Caja",
  "/salesReportByPaymentMethod": "Reporte de Venta por Método de Pago",
  "/salesReportByRooms": "Reporte de Venta por Habitaciones",
  "/salesReportByRoomTypes": "Reporte de Venta por Tipo de Habitaciones",
  "/reportByRoomTypeQuantity":
    "Reporte de Cantidad de Tipo de Habitaciones Vendidas",
  "/invoiceConfiguration": "Configuración de facturación",
  "/invoiceConfiguration/form": "configuración de factura",
};
