import { Tooltip } from "antd";
import { Draggable } from "@components/Common/Draggable";
import {
  getDate,
  weekStartEndFormat,
  getCalendarDayFormat,
  getCalendarDateFormat,
} from "@utils/date";

import DatePicker from "@components/Common/DatePicker";

export const TimelineFilterComponent = (
  selectedDate: any,
  onDateChange: any
) => {
  const defaultDate = getDate(selectedDate);
  return (
    <DatePicker
      size="large"
      style={{ width: 220 }}
      defaultValue={defaultDate}
      format={weekStartEndFormat}
      onChange={onDateChange}
    />
  );
};

export const draggable = (uuid: string, data: any) => {
  const { reservation, roomsCount } = data;
  return (
    <Draggable id={uuid} data={data}>
      <h4 style={{ marginRight: 10 }}>
        {reservation.reservationIndex}/{roomsCount}
      </h4>
      <Tooltip title={`${reservation.userName} - ${reservation.roomName}`}>
        <h3
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: 300,
          }}
        >
          {reservation.userName}
        </h3>
      </Tooltip>
    </Draggable>
  );
};

export const getCalendarColumnDate = (date: string) => (
  <div>
    <div className="calendar-date-header">{getCalendarDateFormat(date)}</div>
    <div className="calendar-day-header">{getCalendarDayFormat(date)}</div>
  </div>
);
