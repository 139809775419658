import React from "react";
import { ITableToolbarProps } from "./table";
import { Input, Col, Row, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

const TableToolbar: React.FC<ITableToolbarProps> = (props) => {
  const navigate = useNavigate();
  const handleNew = () => {
    navigate("form");
  };

  const onFilterChange = (e: any) => {
    props.onFilterChange(e.target.value);
  };

  return (
    <Row>
      <Col style={{ display: "flex" }} flex={3}>
        <Input
          prefix={<SearchOutlined />}
          size="large"
          placeholder="Buscar..."
          allowClear
          onChange={onFilterChange}
        />
      </Col>
      <Col
        flex={3}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {props.canCreate ? (
          <Button
            style={{ marginRight: "50px" }}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => handleNew()}
          >
            Nuevo
          </Button>
        ) : null}
      </Col>
    </Row>
  );
};

export default TableToolbar;
