import axios from "axios";

export const getErrorMessage = (error: any) => {
  let message = "Se ha producido un error inesperado";
  if (error.response && error.response.data.code) {
    if (error.response.status === 500) {
      message = error.response.data.message;
    } else {
      message = getFirebaseMessage(error.response.data);
    }
  } else if (axios.isAxiosError(error)) {
    if (error.code === "ERR_NETWORK") {
      message = "Error de conectividad";
    }
    if (error.response && error.response.data.code) {
      message = error.response.data.message;
    }
  }
  return message;
};

export const getFirebaseErrorMessage = (error: any) => {
  return getFirebaseMessage(error);
};

export const getFirebaseMessage = (error: any) => {
  switch (error.code) {
    case "auth/invalid-login-credentials":
      return "Credenciales invalidas.";
    case "auth/invalid-password":
      return "La contraseña ingresada es invalida.";
    case "auth/email-already-exists":
      return "El correo ya esta siendo utilizado.";
    case "auth/invalid-email":
      return "Correo invalido.";
    case "auth/user-not-found":
      return "No se encontró usuario.";
    case "auth/wrong-password":
      return "Contraseña incorrecta.";
    case "auth/too-many-requests":
      return "El acceso a esta cuenta ha sido temporalmente desactivado debido a muchos intentos fallidos de inicio de sesión. Puedes restaurarlo inmediatamente restableciendo tu contraseña o puedes intentarlo nuevamente más tarde.";
    default:
      return error.message;
  }
};
