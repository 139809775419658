import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HOTEL_LOGO_URL } from "@constants/globalConstants";
import { getFirebaseErrorMessage } from "@utils/errorMessage";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Card, Form, Spin, Input, Image, Button, message } from "antd";

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(location?.state?.email);

  const onResetPassword = async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      message.success("Se envío un correo para resetear la contraseña");
      setTimeout(() => {
        goBack();
      }, 3000);
    } catch (error: any) {
      message.error(getFirebaseErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => navigate("..");

  return (
    <Spin className="spinner" spinning={loading}>
      <div className="login">
        <Card className="login-card" title={null}>
          <div
            style={{ textAlign: "center", paddingTop: 20, paddingBottom: 40 }}
          >
            <Image
              width="60%"
              height="60%"
              preview={false}
              src={HOTEL_LOGO_URL}
            />
          </div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onResetPassword}
            size={"large"}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Correo no es valido.",
                },
              ]}
              initialValue={email}
            >
              <Input
                className="input-class"
                placeholder="Correo electrónico"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Enviar correo
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                type="default"
                style={{
                  width: "100%",
                }}
                onClick={goBack}
              >
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Spin>
  );
};

export default ResetPassword;
