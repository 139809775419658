import React, { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import { Layout, Modal, Table, message } from "antd";
import { getToday, getDateString } from "@utils/date";
import { getErrorMessage } from "@utils/errorMessage";
import { Droppable } from "@components/Common/Droppable";
import { DndContext, pointerWithin } from "@dnd-kit/core";
import { draggable } from "@components/Timeline/Components";
import { reservationStatus } from "@constants/reservationStatus";
import { getTimeline, updateReservation } from "@services/timelineService";

import {
  getColumns,
  getDataSource,
  getTimelineReservation,
} from "@components/Timeline/timeline";

import ITimeline from "@interfaces/ITimeline";
import scrollIntoView from "scroll-into-view";
import TimelineModal from "@components/Timeline/Modal";

const Timeline: React.FC = () => {
  const [data, setData] = useState<ITimeline>();
  const [loading, setLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [dragObject, setDragObject] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>(() => getToday());
  const [selectedReservationId, setSelectedReservationId] = useState<any>(null);

  useEffect(() => {
    fetchTimelineData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    scrollIntoView(document.querySelector(".row-class"));
  }, [data]);

  const fetchTimelineData = async () => {
    try {
      setLoading(true);
      const timelineData = await getTimeline(selectedDate);
      setData(timelineData);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onDateChange = (selectedDate: any) => {
    const newDate = getDateString(selectedDate);
    setSelectedDate(newDate);
  };

  const droppable = (uid: string, data: any) => (
    <div onClick={() => setEditModalOpen(true)}>
      <Droppable id={uid} data={data}></Droppable>
    </div>
  );

  const columns: any = getColumns(data, selectedDate, onDateChange);
  const dataSource = getDataSource(data, selectedDate, draggable, droppable);

  const handleDragEnd = async (event: any) => {
    const active = event?.active?.data?.current;
    const over = event?.over?.data?.current;
    if (over && active?.reservation?.status === reservationStatus.COMPLETED) {
      message.warning("Reservaciones completadas no se pueden modificar.");
      return;
    }
    if (!over) {
      openReservationModal(active?.reservation);
    } else {
      setDragObject({ active, over });
    }
  };

  const handleUpdateReservation = async ({ active, over }: any) => {
    try {
      setLoading(true);
      const reservationObject = getTimelineReservation(active, over);
      await updateReservation(reservationObject);
      await fetchTimelineData();
      message.success("Reservación actualizada!");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setDragObject(null);
      setLoading(false);
    }
  };

  const openReservationModal = (reservation: any) => {
    setSelectedReservationId(reservation.reservationId);
    setEditModalOpen(true);
  };

  const onReload = async () => {
    await fetchTimelineData();
    onModalClose();
  };

  const onModalClose = () => {
    setEditModalOpen(false);
    setSelectedReservationId(null);
  };

  return (
    <Layout>
      <Content>
        <DndContext
          onDragEnd={handleDragEnd}
          collisionDetection={pointerWithin}
        >
          <Table
            rowClassName="row-class"
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowKey={(record) => record.id}
            scroll={{ x: "max-content" }}
            pagination={false}
          />
        </DndContext>

        {editModalOpen && (
          <TimelineModal
            open={editModalOpen}
            reservationId={selectedReservationId}
            onReload={() => onReload()}
            onCancel={() => onModalClose()}
          ></TimelineModal>
        )}

        {dragObject && (
          <Modal
            title={`Mover reservación`}
            open={dragObject}
            centered
            okText="Confirmar"
            cancelText="Cancelar"
            onOk={() => handleUpdateReservation(dragObject)}
            onCancel={() => setDragObject(null)}
          >
            <p>
              Esta seguro de modificar la reservación de{" "}
              <b>{dragObject?.active?.reservation?.userName}</b>?
            </p>
          </Modal>
        )}
      </Content>
    </Layout>
  );
};

export default Timeline;
