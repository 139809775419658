import axios from "axios";
import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api";

export const verifyAuth = async () => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/verify-auth`, headers);
    return response.data;
  } catch (error) {
    console.error("Error verifying authorization:", error);
    throw error;
  }
};
