import React from "react";

const Support = () => {
  return (
    <>
      Holaaaaa
    </>
  );
};

export default Support;
