import { ROLE } from "@constants/role";
import { reservationStatus } from "@constants/reservationStatus";

import IReservation from "@interfaces/IReservation";

export interface IActionProps {
  label: string;
  dataObject: any;
  onDelete: Function;
  onConfirm?: Function;
  disabled: boolean;
  showEdit: boolean;
  showDelete: boolean;
}

export interface IReservationInvoiceComponentProps {
  dataObject: any;
  onConfirm?: Function;
}

export interface ICheckinProps {
  dataObject: any;
  onReload: Function;
}

export interface ITableToolbarProps {
  onFilterChange: Function;
  canCreate: boolean;
}

export interface IDateToolbarProps {
  filteredDate: string;
  setFilteredDate: any;
}

export const getStatusColor = (status: string) => {
  switch (status) {
    case reservationStatus.RESERVED:
      return "#2980B9";
    case reservationStatus.CHECKIN:
      return "green";
    case reservationStatus.CHECKOUT:
      return "#f50";
    case reservationStatus.COMPLETED:
      return "#808080";
    default:
      return "#2980B9";
  }
};

export const getStatusBackgroundColor = (reservation: IReservation) => {
  if (reservation?.companyName === "Expedia") {
    return "#FEBF4F";
  }
  if (reservation?.companyName === "Booking") {
    return "#003580";
  }

  switch (reservation?.status) {
    case reservationStatus.RESERVED:
      return "#AED6F1";
    case reservationStatus.CHECKIN:
      return "#A2D9CE";
    case reservationStatus.CHECKOUT:
      return "#FFA07A";
    case reservationStatus.COMPLETED:
      return "#808080";
    default:
      return "#AED6F1";
  }
};

export const getRoleColor = (status: string) => {
  switch (status) {
    case ROLE.ADMIN:
      return "gold";
    case ROLE.GUEST:
      return "blue";
    case ROLE.RECEPCIONIST:
      return "green";
    default:
      return "blue";
  }
};
