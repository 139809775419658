import React, { useEffect, useState } from "react";
import IRoom from "@interfaces/IRoom";
import IFieldData from "@interfaces/IFieldData";

import { roomTypes } from "@constants/roomTypes";
import { formLayout } from "@constants/formLayout";
import { getErrorMessage } from "@utils/errorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { createRoom, updateRoom } from "@services/roomService";

import {
  IFormProps,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";

import {
  Card,
  Form,
  Input,
  Select,
  Space,
  message,
  Button,
  Spin,
  InputNumber,
} from "antd";

const RoomForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const room: IRoom = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<IFieldData[]>([]);

  useEffect(() => {
    setLoading(true);
    loadRoom();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadRoom = () => {
    if (!room) return;
    const roomData = getFieldData(room);
    setFields(roomData);
  };

  const goBack = () => (props.onReload ? props.onReload() : navigate(".."));
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (roomData: IRoom) => {
    try {
      setLoading(true);
      if (room?.id) {
        await updateRoom(roomData);
        message.success("Habitación actualizada!");
      } else {
        await createRoom(roomData);
        message.success("Habitación creada!");
      }
      goBack();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card bordered={false} style={{ width: 600 }}>
        <Form
          fields={fields}
          {...formLayout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="id" hidden />
          <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="type"
            label="Tipo"
            rules={[{ required: true }]}
            initialValue={roomTypes.SINGLE}
          >
            <Select>
              <Select.Option value={roomTypes.SINGLE}>
                {roomTypes.SINGLE}
              </Select.Option>
              <Select.Option value={roomTypes.DOUBLE}>
                {roomTypes.DOUBLE}
              </Select.Option>
              <Select.Option value={roomTypes.TRIPLE}>
                {roomTypes.TRIPLE}
              </Select.Option>
              <Select.Option value={roomTypes.JRSUITE}>
                {roomTypes.JRSUITE}
              </Select.Option>
              <Select.Option value={roomTypes.SUITE}>
                {roomTypes.SUITE}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="price"
            label="Precio"
            initialValue={0}
            rules={[{ required: true }]}
          >
            <InputNumber
              min={0}
              controls={true}
              addonBefore="L."
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {room?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => handleCancel()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default RoomForm;
