import { useEffect, useState } from "react";
import { getDateString } from "@utils/date";
import { getCurrencyFormat } from "@utils/util";
import { connectAndPrint } from "@utils/printer";
import Table, { ColumnsType } from "antd/es/table";
import { getErrorMessage } from "@utils/errorMessage";
import { Button, Card, message, Tooltip } from "antd";
import { StopOutlined, PrinterOutlined } from "@ant-design/icons";
import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { deleteInvoice, getInvoiceDetailsById } from "@services/invoiceService";

import IInvoice from "@interfaces/IInvoice";
import IReservationProduct from "@interfaces/IReservationProduct";
import IReservationService from "@interfaces/IReservationService";
import IReservationRoomsPayment from "@interfaces/IReservationRoomsPayment";

const InvoicesDetails: React.FC<any> = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState<any>([]);
  const [invoicesData, setInvoicesData] = useState<IInvoice[]>([]);

  const [selectedRowProducts, setSelectedRowProducts] = useState<
    IReservationProduct[]
  >([]);
  const [selectedRowServices, setSelectedRowServices] = useState<
    IReservationService[]
  >([]);
  const [selectedRowRoomsPayments, setSelectedRowRoomsPayments] = useState<
    IReservationRoomsPayment[]
  >([]);

  useEffect(() => {
    if (props?.reservation?.invoices) {
      setInvoicesData(props?.reservation.invoices);
    }
  }, [props.reservation?.invoices]);

  const columns: ColumnsType<IInvoice> = [
    {
      key: "createdAt",
      title: "Fecha",
      dataIndex: "createdAt",
      render: (createdAt) => getDateString(createdAt),
    },
    {
      key: "invoiceClientName",
      title: "Cliente",
      dataIndex: "invoiceClientName",
    },
    {
      key: "paymentMethod",
      title: "Método de pago",
      dataIndex: "paymentMethod",
    },
    {
      key: "invoiceName",
      title: "Tipo",
      dataIndex: "invoiceName",
    },
    {
      key: "createdBy",
      title: "Cajero",
      dataIndex: "createdBy",
    },
    {
      key: "total",
      title: "Total",
      dataIndex: "total",
      render: (total: number) => `L. ${getCurrencyFormat(total)}`,
    },
    {
      key: "x",
      dataIndex: "",
      title: "Acción",
      align: "center",
      width: 100,
      render: (invoice: IInvoice) => (
        <>
          <Tooltip title="Imprimir">
            <Button
              ghost
              className="ghost-button"
              icon={<PrinterOutlined onClick={() => onPrint(invoice)} />}
            />
          </Tooltip>
          {invoice.invoiceName !== "Caja 1" && (
            <Tooltip title="Anular">
              <Button
                ghost
                className="ghost-button"
                icon={<StopOutlined onClick={() => onDelete(invoice)} />}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const onPrint = async (invoice: IInvoice) => {
    try {
      setLoading(true);
      if (invoice) {
        await connectAndPrint([invoice]);
      }
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (invoice: IInvoice) => {
    try {
      setLoading(true);
      await deleteInvoice(invoice.id);
      message.success("Factura borrada exitosamente!");
      props.goBack();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const expandableComponent = {
    expandedRowKeys: selectedRowKey,
    onExpand: async (expanded: boolean, record: any) => {
      if (expanded) {
        const { products, services, roomsPayments } =
          await getInvoiceDetailsById(record.id);
        setSelectedRowProducts(products);
        setSelectedRowServices(services);
        setSelectedRowRoomsPayments(roomsPayments);
        setSelectedRowKey([record.id]);
      } else {
        setSelectedRowKey([]);
      }
    },
    expandedRowRender: (record: any) => (
      <>
        <Card className="mb" title="Detalles">
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Factura Autorizada </b>
            <br />
            <span>{record.invoiceAuthorizedNumber}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>C.A.I. </b>
            <br />
            <span>{record.invoiceCai}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Notas </b>
            <br />
            <span>{record.invoiceNotes}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail"></Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>D.N.I. Cliente </b>
            <br />
            <span>{record.invoiceClientDni}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>R.T.N. Cliente </b>
            <br />
            <span>{record.invoiceClientRtn}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Nombre Empresa </b>
            <br />
            <span>{record.invoiceCompanyName}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>R.T.N. Empresa </b>
            <br />
            <span>{record.invoiceCompanyRtn}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Subtotal </b>
            <br />
            <span>L. {getCurrencyFormat(record.subtotal)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Descuento </b>
            <br />
            <span>L. {getCurrencyFormat(record.discount)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>I.S.V. </b>
            <br />
            <span>L. {getCurrencyFormat(record.isvTax)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>I.S.R.T. </b>
            <br />
            <span>L. {getCurrencyFormat(record.isrtTax)}</span>
          </Card.Grid>
        </Card>
        {[
          ...selectedRowProducts,
          ...selectedRowServices,
          ...selectedRowRoomsPayments,
        ].length > 0 && (
          <Card className="mb" title="Pagos">
            {[
              ...selectedRowProducts,
              ...selectedRowServices,
              ...selectedRowRoomsPayments,
            ]?.map((payment: any) => (
              <Card.Grid
                key={payment.id}
                hoverable={false}
                className="table-row-detail"
              >
                <b>{payment.name}</b>
                <br />
                <b>Precio:</b> <span>L.{getCurrencyFormat(payment.price)}</span>
                <br />
                {payment.date ? (
                  <>
                    <span>{getDateString(payment.date)}</span>
                    <br />
                  </>
                ) : (
                  <>
                    <b>Cantidad:</b> <span>{payment.quantity}</span>
                    <br />
                  </>
                )}
                <br />
                {payment.invoiceIds ? (
                  <CheckCircleFilled
                    style={{
                      color: "green",
                      fontSize: "32px",
                    }}
                  />
                ) : (
                  <MinusCircleFilled
                    style={{
                      color: "red",
                      fontSize: "32px",
                    }}
                  />
                )}
              </Card.Grid>
            ))}
          </Card>
        )}
        <Card className="mb" title="Historial de Cambios">
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Creado Por </b>
            <br />
            <span>{record.createdBy}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Modificado Por </b>
            <br />
            <span>{record.modifiedBy}</span>
          </Card.Grid>
        </Card>
      </>
    ),
  };

  return (
    <Table
      rowKey={(record) => `${record.id}`}
      columns={columns}
      loading={loading}
      dataSource={invoicesData}
      expandable={expandableComponent}
      pagination={false}
    />
  );
};

export default InvoicesDetails;
