import React, { useEffect, useState } from "react";
import IFieldData from "@interfaces/IFieldData";
import DatePicker from "@components/Common/DatePicker";
import IInvoiceConfiguration from "@interfaces/IInvoiceConfiguration";

import { DATE_FORMAT } from "@constants/date";
import { formLayout } from "@constants/formLayout";
import { getErrorMessage } from "@utils/errorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { getFieldData, onFinishFailed } from "@components/Form/form";
import { createInvoiceConfiguration } from "@services/invoiceConfigurationService";

import {
  Card,
  Form,
  Spin,
  Input,
  Space,
  Button,
  message,
  InputNumber,
} from "antd";

const InvoiceConfigurationForm = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const invoiceConfiguration: IInvoiceConfiguration = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<IFieldData[]>([]);

  useEffect(() => {
    loadInvoiceConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInvoiceConfiguration = () => {
    if (!invoiceConfiguration) return;
    setLoading(true);
    const invoiceConfigurationData = getFieldData(invoiceConfiguration);
    setFields(invoiceConfigurationData);
    setLoading(false);
  };

  const goBack = () => (props.onReload ? props.onReload() : navigate(".."));
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (invoiceConfigurationData: IInvoiceConfiguration) => {
    try {
      setLoading(true);
      await createInvoiceConfiguration(invoiceConfigurationData);
      message.success("Configuracion de facturación creado!");

      goBack();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card bordered={false} style={{ width: 600 }}>
        <Form
          fields={fields}
          {...formLayout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="id" hidden />

          <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item name="cai" label="CAI" rules={[{ required: true }]}>
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="deadlineForIssuance"
            label="Fecha limite emisión"
            rules={[{ required: true }]}
          >
            <DatePicker
              format={DATE_FORMAT}
              inputReadOnly
              style={{ width: "100%" }}
              placeholder="Elegir fecha"
            />
          </Form.Item>

          <Form.Item
            name="initialAuthorizedRange"
            label="Rango inicial autorizado"
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="finalAuthorizedRange"
            label="Rango final autorizado"
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="totalNumeration"
            label="Numeración total"
            rules={[{ required: true }]}
            initialValue={0}
          >
            <InputNumber controls={true} min={0} />
          </Form.Item>

          <Form.Item>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                Crear
              </Button>
              <Button onClick={() => handleCancel()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default InvoiceConfigurationForm;
