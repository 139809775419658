import React, { Key, useEffect, useState } from "react";
import { Table, Layout, Card, message } from "antd";
import { getErrorMessage } from "@utils/errorMessage";
import { SorterResult } from "antd/es/table/interface";
import { getServices } from "@services/serviceService";
import type { ColumnsType, TableProps } from "antd/es/table";
import { getCurrencyFormat, isFilterMatched, sortAlphaNum } from "@utils/util";

import IService from "@interfaces/IService";
import TableToolbar from "@components/Table/TableToolbar";
import ActionComponent from "@components/Table/ActionComponent";

const { Header, Content } = Layout;

const ServicesTable: React.FC = () => {
  const [data, setData] = useState<IService[]>([]);
  const [loading, setLoading] = useState(false);
  const [filteredString, setFilteredString] = useState<Key>("");
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IService>>({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const serviceData = await getServices();
      setData(serviceData);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<IService> = [
    {
      key: "name",
      title: "Nombre",
      dataIndex: "name",
      align: "center",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => sortAlphaNum(a.name, b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      key: "description",
      title: "Descripción",
      dataIndex: "description",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.description, b.description),
      sortOrder: sortedInfo.columnKey === "description" ? sortedInfo.order : null,
    },
    {
      key: "price",
      title: "Precio",
      dataIndex: "price",
      align: "center",
      sorter: (a, b) => a.price - b.price,
      sortOrder: sortedInfo.columnKey === "price" ? sortedInfo.order : null,
      render: (price: number) => `L. ${getCurrencyFormat(price)}`,
    },
    {
      key: "x",
      dataIndex: "",
      title: "Acción",
      align: "center",
      width: 100,
      render: (service: IService) => (
        <ActionComponent
          label="servicio"
          dataObject={service}
          onDelete={() => {}}
          disabled={false}
          showEdit
          showDelete={false}
        />
      ),
    },
  ];

  const handleTableChange: TableProps<IService>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IService>);
  };

  const handleFilterChange = (value: string) => {
    setFilteredString(value);
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={handleFilterChange} canCreate />
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            pagination={{ pageSize: 20 }}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default ServicesTable;
