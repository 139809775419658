import React, { Key, useEffect, useState } from "react";
import { getDateString } from "@utils/date";
import { getErrorMessage } from "@utils/errorMessage";
import { getRoleColor } from "@components/Table/table";
import { SorterResult } from "antd/es/table/interface";
import { Table, Layout, Card, message, Tag } from "antd";
import { isFilterMatched, sortAlphaNum } from "@utils/util";
import { deleteUser, getUsers } from "@services/userService";
import type { ColumnsType, TableProps } from "antd/es/table";

import IUser from "@interfaces/IUser";
import TableToolbar from "@components/Table/TableToolbar";
import ActionComponent from "@components/Table/ActionComponent";

const { Header, Content } = Layout;

const UsersTable: React.FC = () => {
  const [data, setData] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IUser>>({});
  const [filteredString, setFilteredString] = useState<Key>("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const userData = await getUsers();
      setData(userData);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<IUser> = [
    {
      key: "name",
      title: "Nombre y Apellido",
      dataIndex: "name",
      align: "center",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => sortAlphaNum(a.name, b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      key: "phone",
      title: "Teléfono",
      dataIndex: "phone",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.phone, b.phone),
      sortOrder: sortedInfo.columnKey === "phone" ? sortedInfo.order : null,
    },
    {
      key: "dni",
      title: "D.N.I.",
      dataIndex: "dni",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.dni, b.dni),
      sortOrder: sortedInfo.columnKey === "dni" ? sortedInfo.order : null,
    },
    {
      key: "rtn",
      title: "R.T.N.",
      dataIndex: "rtn",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.rtn, b.rtn),
      sortOrder: sortedInfo.columnKey === "rtn" ? sortedInfo.order : null,
    },
    {
      key: "role",
      title: "Role",
      dataIndex: "role",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.role, b.role),
      sortOrder: sortedInfo.columnKey === "role" ? sortedInfo.order : null,
      render: (role) => <Tag color={getRoleColor(role)}>{role}</Tag>,
    },
    {
      key: "x",
      dataIndex: "",
      title: "Acción",
      align: "center",
      width: 100,
      render: (user: IUser) => (
        <ActionComponent
          label="usuario"
          dataObject={user}
          onDelete={async () => await onDelete(user.id)}
          disabled={false}
          showEdit
          showDelete
        />
      ),
    },
  ];

  const handleTableChange: TableProps<IUser>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IUser>);
  };

  const handleFilterChange = (value: string) => {
    setFilteredString(value);
  };

  const onDelete = async (id: string) => {
    try {
      setLoading(true);
      await deleteUser(id);
      await fetchData();
      message.success("Usuario borrado exitosamente!");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const defaultExpandable = {
    expandedRowRender: (record: any) => (
      <>
        <Card className="mb" title="Detalles">
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Correo </b> <br />
            <span>{record.email}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Pasaporte </b> <br /> <span>{record.passport}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Fecha de Nacimiento </b> <br />
            <span>{getDateString(record.birthday)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail"></Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Género </b>
            <br />
            <span>{record.gender}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Ciudad</b> <br /> <span>{record.city}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>País</b> <br /> <span>{record.country}</span>
          </Card.Grid>
        </Card>
        <Card className="mb" title="Historial de Cambios">
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Creado Por </b>
            <br />
            <span>{record.createdBy}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Modificado Por </b>
            <br />
            <span>{record.modifiedBy}</span>
          </Card.Grid>
        </Card>
      </>
    ),
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={handleFilterChange} canCreate />
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            expandable={defaultExpandable}
            pagination={{ pageSize: 20 }}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default UsersTable;
