import React, { useEffect, useState } from "react";
import ICompany from "@interfaces/ICompany";
import IFieldData from "@interfaces/IFieldData";
import PhonePrefix from "@components/Common/PhonePrefix";
import CountryLib from "countrycitystatejson";

import { getHondurasCities } from "@utils/util";
import { formLayout } from "@constants/formLayout";
import { getErrorMessage } from "@utils/errorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { createCompany, updateCompany } from "@services/companyService";
import { Card, Form, Input, Spin, Space, Select, Button, message } from "antd";

import {
  validateRtnLength,
  validateOnlyNumbers,
  validateSomeSpecialChars,
  validateHonduranPhoneNumber,
} from "@utils/validation";

import {
  renderItem,
  IFormProps,
  filterData,
  IRenderItem,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";

const CompanyForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const company: ICompany = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<IFieldData[]>([]);
  const [cityOptions, setCityOptions] = useState<IRenderItem[]>([]);
  const [countryOptions, setCountryOptions] = useState<IRenderItem[]>([]);

  useEffect(() => {
    loadCountriesData();
    loadCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCountriesData = async () => {
    const countries = CountryLib.getCountries();
    const countryOptions: IRenderItem[] = countries?.map((country: any) => {
      return renderItem({ id: country.shortName, name: country.name });
    });
    setCountryOptions(countryOptions);
    loadCities("HN");
  };

  const loadCities = (shortName: string) => {
    let cities: any;
    if (shortName === "HN") {
      cities = getHondurasCities();
    } else {
      const country = CountryLib.getCountryByShort(shortName);
      cities = Object.values(country.states).flatMap((x) => x);
    }
    const citiesOptions: IRenderItem[] = cities?.map((city: any) => {
      return renderItem({ id: city.name, name: city.name });
    });
    setCityOptions(citiesOptions);
  };

  const loadCompany = () => {
    if (!company) return;
    setLoading(true);
    const companyData = getFieldData(company);
    setFields(companyData);
    setLoading(false);
  };

  const goBack = (companyId: string = "") =>
    props.onReload ? props.onReload(companyId) : navigate("..");
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (companyData: ICompany) => {
    try {
      setLoading(true);
      let newCompanyId;
      if (company?.id) {
        await updateCompany(companyData);
        message.success("Empresa actualizada!");
      } else {
        newCompanyId = await createCompany(companyData);
        message.success("Empresa creada!");
      }
      goBack(newCompanyId);
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = (_: any, value: any) => {
    form.setFieldsValue({ city: "" });
    loadCities(value.key);
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card bordered={false} style={{ width: 600 }}>
        <Form
          form={form}
          fields={fields}
          {...formLayout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={{ required: " " }}
        >
          <Form.Item name="id" hidden />
          <Form.Item
            name="name"
            label="Nombre"
            rules={[
              { required: true },
              { validator: validateSomeSpecialChars },
            ]}
          >
            <Input maxLength={80} />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Teléfono"
            dependencies={["phonePrefix"]}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const phonePrefix = getFieldValue("phonePrefix");
                  return validateHonduranPhoneNumber(_, phonePrefix, value);
                },
              }),
            ]}
          >
            <Input addonBefore={<PhonePrefix />} maxLength={15} />
          </Form.Item>
          <Form.Item
            name="rtn"
            label="R.T.N."
            rules={[
              { validator: validateRtnLength },
              { validator: validateOnlyNumbers },
            ]}
          >
            <Input maxLength={14} />
          </Form.Item>

          {!props.excludeFields && (
            <>
              <Form.Item
                name="email"
                label="Correo"
                rules={[
                  {
                    type: "email",
                    message: "Correo no es valido.",
                  },
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item name="address" label="Dirección">
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item name="city" label="Ciudad">
                <Select
                  showSearch
                  options={cityOptions}
                  notFoundContent="Ciudad no encontrado"
                  filterOption={filterData}
                ></Select>
              </Form.Item>
              <Form.Item name="country" label="País" initialValue="Honduras">
                <Select
                  showSearch
                  options={countryOptions}
                  onChange={handleCountryChange}
                  notFoundContent="País no encontrado"
                  filterOption={filterData}
                ></Select>
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {company?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => handleCancel()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default CompanyForm;
