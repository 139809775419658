import React, { useEffect, useState } from "react";
import { getCurrencyFormat } from "@utils/util";
import { getErrorMessage } from "@utils/errorMessage";
import { Card, Modal, Spin, message } from "antd";
import { reservationStatus } from "@constants/reservationStatus";
import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";

import {
  getTimeString,
  getNumberOfDays,
  getLongDateString,
  getShortDateTimeString,
} from "@utils/date";

import {
  getReservation,
  deleteReservation,
  getReservationDetailsByReservationId,
} from "@services/reservationService";

import ReservationForm from "@pages/Reservations/ReservationForm";
import IReservationProduct from "@interfaces/IReservationProduct";
import IReservationService from "@interfaces/IReservationService";
import ReservationInvoice from "@pages/Reservations/ReservationInvoice";
import IReservationRoomsPayment from "@interfaces/IReservationRoomsPayment";
import ActionComponent from "@components/Table/Reservation/ActionComponent";

const TimelineModal: React.FC<any> = (props) => {
  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState<any>();
  const [editModalOpen, setEditModalOpen] = useState(!props?.reservationId);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const [reservationRoomsPayments, setReservationRoomsPayments] = useState<
    IReservationRoomsPayment[]
  >([]);
  const [reservationProducts, setReservationProducts] = useState<
    IReservationProduct[]
  >([]);
  const [reservationServices, setReservationServices] = useState<
    IReservationService[]
  >([]);

  useEffect(() => {
    const reservationId = props?.reservationId;
    if (reservationId) {
      fetchReservation(reservationId);
      fetchReservationDetailsByReservationId(reservationId);
    }
  }, [props.reservationId]);

  const fetchReservation = async (id: string) => {
    try {
      setLoading(true);
      const reservation = await getReservation(id);
      setReservation(reservation);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return {};
    } finally {
      setLoading(false);
    }
  };

  const fetchReservationDetailsByReservationId = async (id: string) => {
    try {
      setLoading(true);
      const { products, services, roomsPayments } =
        await getReservationDetailsByReservationId(id);
      setReservationProducts(products);
      setReservationServices(services);
      setReservationRoomsPayments(roomsPayments);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return {};
    } finally {
      setLoading(false);
    }
  };

  const closeAll = () => {
    setEditModalOpen(false);
    props.onCancel();
  };

  const onDelete = async () => {
    if (!reservation) return;
    try {
      await deleteReservation(reservation.id);
      message.success("Reservación borrada exitosamente!");
      props.onReload();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    }
  };

  const onReload = async () => {
    props.onReload();
  };

  const getModalHeader = () => (
    <div className="info-modal">
      <div className="info-modal-title">
        <h2 style={{ marginTop: 0 }}>{reservation?.userName}</h2>
      </div>
      {reservation.status !== reservationStatus.COMPLETED && (
        <div className="info-modal-actions">
          <ActionComponent
            label="reservación"
            dataObject={reservation}
            onDelete={onDelete}
            onReload={onReload}
            onEdit={() => setEditModalOpen(true)}
            onPayment={() => setPaymentModalOpen(true)}
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      {props.open && reservation?.id && (
        <Modal
          title={getModalHeader()}
          open={props.open}
          width={1200}
          centered
          footer={[]}
          onCancel={() => {
            props.onCancel();
          }}
        >
          {reservation && (
            <Spin className="spinner" spinning={loading}>
              <Card className="mb" title="Detalles">
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Fechas </b>
                  <br />
                  <span>
                    {getLongDateString(reservation.initialDate)}
                    <br />
                    {getLongDateString(reservation.finalDate)}
                  </span>
                </Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Hora de llegada </b>
                  <br />
                  <span>{getTimeString(reservation.arrivalTime)}</span>
                </Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Noches </b>
                  <br />
                  <span>
                    {getNumberOfDays(
                      reservation.initialDate,
                      reservation.finalDate
                    )}
                  </span>
                </Card.Grid>
                <Card.Grid
                  hoverable={false}
                  className="table-row-detail"
                ></Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Checkin </b>
                  <br />
                  <span>{getShortDateTimeString(reservation.checkinAt)}</span>
                </Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Checkout </b>
                  <br />
                  <span>{getShortDateTimeString(reservation.checkoutAt)}</span>
                </Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Huespedes </b>
                  <br />
                  <span>{reservation.guests}</span>
                </Card.Grid>
                <Card.Grid
                  hoverable={false}
                  className="table-row-detail"
                ></Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>D.N.I. Cliente </b>
                  <br />
                  <span>{reservation.userDni}</span>
                </Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>R.T.N. Cliente </b>
                  <br />
                  <span>{reservation.userRtn}</span>
                </Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Teléfono Cliente</b>
                  <br />
                  <span>
                    {reservation.userPhonePrefix} {reservation.userPhone}
                  </span>
                </Card.Grid>
                <Card.Grid
                  hoverable={false}
                  className="table-row-detail"
                ></Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Nombre Empresa </b>
                  <br />
                  <span>{reservation.companyName}</span>
                </Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>R.T.N. Empresa </b>
                  <br />
                  <span>{reservation.companyRtn}</span>
                </Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Teléfono Empresa</b>
                  <br />
                  <span>
                    {reservation.companyPhonePrefix} {reservation.companyPhone}
                  </span>
                </Card.Grid>
              </Card>
              {[
                ...reservationRoomsPayments,
                ...reservationProducts,
                ...reservationServices,
              ].length > 0 && (
                <Card className="mb" title="Pagos">
                  {[
                    ...reservationRoomsPayments,
                    ...reservationProducts,
                    ...reservationServices,
                  ]?.map((payment: any) => (
                    <Card.Grid
                      key={payment.id}
                      hoverable={false}
                      className="table-row-detail"
                    >
                      <b>{payment.name}</b>
                      <br />
                      <b>Precio:</b>{" "}
                      <span>L.{getCurrencyFormat(payment.price)}</span>
                      <br />
                      {payment.date ? (
                        <>
                          <span>{payment.date}</span>
                          <br />
                        </>
                      ) : (
                        <>
                          <b>Cantidad:</b> <span>{payment.quantity}</span>
                          <br />
                        </>
                      )}
                      <br />
                      {payment.invoiceIds ? (
                        <CheckCircleFilled
                          style={{
                            color: "green",
                            fontSize: "32px",
                          }}
                        />
                      ) : (
                        <MinusCircleFilled
                          style={{
                            color: "red",
                            fontSize: "32px",
                          }}
                        />
                      )}
                    </Card.Grid>
                  ))}
                </Card>
              )}
              {reservation.notes && (
                <Card className="mb" title="Notas">
                  <Card.Grid
                    hoverable={false}
                    className="table-row-detail-single "
                  >
                    <span>{reservation.notes}</span>
                  </Card.Grid>
                </Card>
              )}
              <Card className="mb" title="Historial de Cambios">
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Creado Por </b>
                  <br />
                  <span>{reservation.createdBy}</span>
                </Card.Grid>
                <Card.Grid hoverable={false} className="table-row-detail">
                  <b>Modificado Por </b>
                  <br />
                  <span>{reservation.modifiedBy}</span>
                </Card.Grid>
              </Card>
            </Spin>
          )}
        </Modal>
      )}

      {editModalOpen && (
        <Modal
          open={editModalOpen}
          centered
          width={1480}
          footer={[]}
          onCancel={() => closeAll()}
        >
          <ReservationForm
            dataObject={reservation}
            onReload={() => props.onReload()}
            onCancel={() => closeAll()}
          />
        </Modal>
      )}

      {paymentModalOpen && (
        <Modal
          open={paymentModalOpen}
          centered
          width={1800}
          footer={[]}
          onCancel={() => closeAll()}
        >
          <ReservationInvoice
            dataObject={reservation}
            onReload={() => props.onReload()}
            onCancel={() => closeAll()}
          />
        </Modal>
      )}
    </>
  );
};

export default TimelineModal;
