import { Card } from "antd";
import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { getRooms } from "@services/roomService";
import { useEffect, useRef, useState } from "react";
import { getDateStringDatabase } from "@utils/date";
import { generateSalesReportByRooms } from "@services/reportService";
import {
  getSalesChartByRoomsData,
  getDetailsComponent,
  parseCurrency,
  ReportByRooms,
} from "./Common";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";

import SalesReportToolbar from "./SalesReportToolbar";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(CategoryScale, LinearScale, BarElement);

const SalesReportByRooms = ({ isAdmin }: any) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>(null);
  const [reportData, setReportData] = useState([]);
  const [filteredDate, setFilteredDate] = useState([]);

  const chartRef = useRef<any>(null);
  const [chart, setChart] = useState();

  useEffect(() => {
    if(!isAdmin) {
      navigate("/");
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (reportData && reportData.length > 0) {
      generateChart(reportData);
    }
  }, [reportData]);

  const saveChart = () => {
    setChart(chartRef.current);
  };

  const options: any = {
    responsive: true,
    animation: {
      onComplete: ({ initial }: any) => {
        if (initial) saveChart();
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        font: { size: "14" },
        formatter: parseCurrency,
      },
    },
  };

  const handleFilterDateChange = (value: any) => {
    setFilteredDate(value);
  };

  const handleOnGenerate = async () => {
    if (filteredDate.length < 2) return;

    const payload = {
      initialDate: getDateStringDatabase(filteredDate[0]),
      finalDate: getDateStringDatabase(filteredDate[1]),
    };
    const result = await generateSalesReportByRooms(payload);
    setReportData(result);
  };

  const generateChart = async (data: any) => {
    const rooms = await getRooms();
    const chartData = getSalesChartByRoomsData(rooms, data);
    setData(chartData);
  };

  const pdfComponent = () => (
    <ReportByRooms
      chartRef={chart}
      reportData={reportData}
      filteredDate={filteredDate}
    />
  );

  return (
    <Card>
      <SalesReportToolbar
        chart={chart}
        pdfComponent={pdfComponent()}
        onGenerateClick={handleOnGenerate}
        onFilteredDateChange={handleFilterDateChange}
      />

      {data && <>{getDetailsComponent(filteredDate, reportData)}</>}

      {data && (
        <Bar
          ref={chartRef}
          data={data}
          plugins={[ChartDataLabels]}
          options={options}
        />
      )}
    </Card>
  );
};

export default SalesReportByRooms;
