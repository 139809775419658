import { PRIMARY_COLOR } from "@constants/globalConstants";
import { Form, Button, Select, Divider, InputNumber } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const ProductsDetails: React.FC<any> = (props) => {
  const shouldDisable = (name: any) => {
    return !!props.form.getFieldValue("products")[name]?.invoiceIds?.length;
  };
  return (
    <div style={{ marginTop: 20 }}>
      <Form.List name="products">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Form.Item key={key} noStyle>
                <Form.Item
                  noStyle
                  {...restField}
                  name={[name, "key"]}
                  rules={[{ required: true }]}
                  validateTrigger={["onChange", "onBlur"]}
                >
                  <Select
                    showSearch
                    options={props.productOptions}
                    disabled={shouldDisable(name)}
                    style={{ width: 300, marginRight: 30 }}
                  ></Select>
                </Form.Item>

                <Form.Item
                  noStyle
                  {...restField}
                  initialValue={1}
                  name={[name, "quantity"]}
                >
                  <InputNumber
                    min={1}
                    controls={true}
                    addonBefore="Cantidad"
                    disabled={shouldDisable(name)}
                    style={{ width: 150, marginRight: 30 }}
                  />
                </Form.Item>

                <Form.Item
                  noStyle
                  {...restField}
                  initialValue={0}
                  name={[name, "price"]}
                >
                  <InputNumber
                    min={0}
                    controls={true}
                    addonBefore="L."
                    disabled={shouldDisable(name)}
                    style={{ width: 150, marginRight: 30 }}
                  />
                </Form.Item>
                {!shouldDisable(name) && (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(name)}
                  />
                )}
                <Divider style={{ marginTop: 20 }} />
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                block
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                style={{ color: PRIMARY_COLOR, borderColor: PRIMARY_COLOR }}
              >
                Agregar producto
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ProductsDetails;
