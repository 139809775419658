import axios from "axios";
import IInvoice from "@interfaces/IInvoice";

import { Moment } from "moment";
import { getConfigHeaders } from "@constants/axiosConfig";
import { getMonthString, getYearString } from "@utils/date";

const API_BASE_URL = "/api/invoice";

export const getInvoices = async (filteredDate: Moment) => {
  try {
    const month = getMonthString(filteredDate);
    const year = getYearString(filteredDate);
    const headers = await getConfigHeaders();
    const response = await axios.get(
      `${API_BASE_URL}/all/${month}/${year}`,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};

export const createInvoice = async (invoiceData: IInvoice) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      invoiceData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error creating invoice:", error);
    throw error;
  }
};

export const getInvoiceDetailsById = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/detail/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching invoice details:", error);
    throw error;
  }
};

export const getInvoiceHistory = async (id: string, reservationId: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(
      `${API_BASE_URL}/history/${id}/${reservationId}`,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching invoice history:", error);
    throw error;
  }
};

export const deleteInvoice = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error deleting invoice:", error);
    throw error;
  }
};
