import React, { useEffect, useState } from "react";
import { getRooms } from "@services/roomService";
import { getErrorMessage } from "@utils/errorMessage";
import { getDateDatabase, getTime } from "@utils/date";
import { getProducts } from "@services/productService";
import { getServices } from "@services/serviceService";
import { reservationLayout } from "@constants/formLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Card, Form, Spin, Space, Button, message } from "antd";

import {
  createReservation,
  updateReservation,
  getReservationDetailsByReservationId,
} from "@services/reservationService";

import {
  IFormProps,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";

import IFieldData from "@interfaces/IFieldData";
import IReservation from "@interfaces/IReservation";
import RoomsDetails from "@components/Reservations/RoomsDetails";
import NotesDetails from "@components/Reservations/NotesDetails";
import ProductsDetails from "@components/Reservations/ProductsDetails";
import InvoicesDetails from "@components/Reservations/InvoicesDetails";
import ServicesDetails from "@components/Reservations/ServicesDetails";
import ReservationDetails from "@components/Reservations/ReservationDetails";

const ReservationForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const reservation: IReservation = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<IFieldData[]>([]);
  const [roomOptions, setRoomOptions] = useState<any>([]);
  const [productOptions, setProductOptions] = useState<any>([]);
  const [serviceOptions, setServiceOptions] = useState<any>([]);
  const [activeTabKey, setActiveTabKey] = useState<string>("1");

  useEffect(() => {
    setLoading(true);
    fetchRoomsData();
    fetchProductsData();
    fetchServicesData();
    fetchReservationData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation]);

  const fetchReservationData = async () => {
    if (reservation?.id) {
      const { id } = reservation;
      reservation.arrivalTime = getTime(reservation.arrivalTime);

      // TODO pasar a cada pantalla individualmente
      const { rooms, products, services, invoices, roomsPayments } =
        await getReservationDetailsByReservationId(id);
      reservation.rooms = rooms;
      reservation.products = products;
      reservation.services = services;
      reservation.invoices = invoices;
      reservation.roomsPayments = roomsPayments;

      // TODO creo que getDateDatabase puede ser lo mismo a .toISOString?
      reservation.dates = [
        getDateDatabase(reservation.initialDate),
        getDateDatabase(reservation.finalDate),
      ];

      setFields(getFieldData(reservation));
    }
  };

  const fetchRoomsData = async () => {
    try {
      const rooms = await getRooms();
      const roomOptions = rooms?.map((room: any) => ({
        key: room.id,
        label: `${room.name} - ${room.type}`,
        value: room.id,
        price: room.price || 0,
        name: room.name,
        type: room.type,
      }));
      setRoomOptions(roomOptions);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    }
  };

  const fetchProductsData = async () => {
    try {
      const products = await getProducts();
      const productOptions = products?.map((product: any) => ({
        key: product.id,
        label: product.name,
        value: product.id,
        name: product.name,
        price: product.price,
      }));
      setProductOptions(productOptions);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    }
  };

  const fetchServicesData = async () => {
    try {
      const services = await getServices();
      const serviceOptions = services?.map((service: any) => ({
        key: service.id,
        label: service.name,
        value: service.id,
        name: service.name,
        price: service.price,
      }));
      setServiceOptions(serviceOptions);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    }
  };

  const onFieldsChangeForm = (changedFields: any) => {
    const [field] = changedFields;
    const [name] = field.name;

    if (name === "rooms") {
      onRoomChange(name, field);
    }

    if (name === "products") {
      onProductChange(name, field);
    }

    if (name === "services") {
      onServiceChange(name, field);
    }
  };

  const onRoomChange = (name: string, field: any) => {
    const index = field.name[1];
    const key = field.name[2];

    if (name !== "rooms" || key !== "key") return;

    const room = roomOptions?.find((x: any) => x.key === field.value);
    const roomName = room.name || "";
    const roomType = room.type || "";
    const roomPrice = room.price || 0;

    const roomsField = form.getFieldValue("rooms");
    roomsField[index].name = roomName;
    roomsField[index].type = roomType;
    roomsField[index].price = roomPrice;
    form.setFieldValue("rooms", roomsField);
  };

  const onProductChange = (name: string, field: any) => {
    const index = field.name[1];
    const key = field.name[2];

    if (name !== "products" || key !== "key") return;

    const product = productOptions?.find((x: any) => x.key === field.value);
    const productName = product.name || "";
    const productPrice = product.price || 0;

    const productsField = form.getFieldValue("products");
    productsField[index].name = productName;
    productsField[index].price = productPrice;
    form.setFieldValue("products", productsField);
  };

  const onServiceChange = (name: string, field: any) => {
    const index = field.name[1];
    const key = field.name[2];

    if (name !== "services" || key !== "key") return;

    const service = serviceOptions?.find((x: any) => x.key === field.value);
    const serviceName = service.name || "";
    const servicePrice = service.price || 0;

    const servicesField = form.getFieldValue("services");
    servicesField[index].name = serviceName;
    servicesField[index].price = servicePrice;
    form.setFieldValue("services", servicesField);
  };

  const goBack = () => (props.onReload ? props.onReload() : navigate(".."));
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (data: IReservation) => {
    try {
      setLoading(true);
      const reservationObject = Object.assign({}, reservation, data);
      if (reservation?.id) {
        await updateReservation(reservationObject);
        message.success("Reservación actualizada!");
      } else {
        await createReservation(reservationObject);
        message.success("Reservación creada!");
      }
      goBack();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const reservationDetails = () => {
    return {
      label: "Reservación",
      card: <ReservationDetails form={form} />,
    };
  };

  const roomDetails = () => {
    return {
      label: "Habitaciones",
      card: <RoomsDetails form={form} roomOptions={roomOptions} />,
    };
  };

  const productsDetails = () => {
    return {
      label: "Productos",
      card: <ProductsDetails form={form} productOptions={productOptions} />,
    };
  };

  const servicesDetails = () => {
    return {
      label: "Servicios",
      card: <ServicesDetails form={form} serviceOptions={serviceOptions} />,
    };
  };

  const notesDetails = () => {
    return {
      label: "Notas",
      card: <NotesDetails />,
    };
  };

  const invoicesDetails = () => {
    return {
      label: "Facturas",
      card: <InvoicesDetails reservation={reservation} goBack={goBack} />,
    };
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card bordered={false} style={{ width: 1400 }}>
        <Form
          {...reservationLayout}
          form={form}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChangeForm}
          initialValues={{ rooms: [] }}
        >
          <Tabs
            type="card"
            size="large"
            defaultActiveKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
            items={[
              reservationDetails(),
              roomDetails(),
              productsDetails(),
              servicesDetails(),
              notesDetails(),
              invoicesDetails(),
            ].map((detail, i) => {
              const id = String(i + 1);
              return {
                key: id,
                forceRender: true,
                label: detail.label,
                children: detail.card,
              };
            })}
          />
          {activeTabKey !== "6" && (
            <Form.Item>
              <Space size="large" style={{ marginTop: 10 }}>
                <Button type="primary" htmlType="submit">
                  {reservation?.id ? "Editar" : "Crear"}
                </Button>
                <Button onClick={() => handleCancel()}>Cancelar</Button>
              </Space>
            </Form.Item>
          )}
        </Form>
      </Card>
    </Spin>
  );
};

export default ReservationForm;
