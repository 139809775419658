import React, { useEffect, useState } from "react";
import IService from "@interfaces/IService";
import IFieldData from "@interfaces/IFieldData";

import { formLayout } from "@constants/formLayout";
import { getErrorMessage } from "@utils/errorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { createService, updateService } from "@services/serviceService";

import {
  IFormProps,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";

import {
  Card,
  Form,
  Spin,
  Input,
  Space,
  Button,
  message,
  InputNumber,
} from "antd";

const ServiceForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const service: IService = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<IFieldData[]>([]);

  useEffect(() => {
    setLoading(true);
    loadService();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadService = () => {
    if (!service) return;
    const serviceData = getFieldData(service);
    setFields(serviceData);
  };

  const goBack = () => (props.onReload ? props.onReload() : navigate(".."));
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (serviceData: IService) => {
    try {
      setLoading(true);
      if (service?.id) {
        await updateService(serviceData);
        message.success("Servicio actualizado!");
      } else {
        await createService(serviceData);
        message.success("Servicio creado!");
      }
      goBack();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card bordered={false} style={{ width: 600 }}>
        <Form
          fields={fields}
          {...formLayout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="id" hidden />

          <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item name="description" label="Descripción">
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="price"
            label="Precio"
            initialValue={0}
            rules={[{ required: true }]}
          >
            <InputNumber
              min={0}
              controls={true}
              addonBefore="L."
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {service?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => handleCancel()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default ServiceForm;
