import axios from "axios";
import IInvoiceConfiguration from "@interfaces/IInvoiceConfiguration";

import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/invoiceConfiguration";

export const getInvoiceConfiguration = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching invoice configuration:", error);
    throw error;
  }
};

export const getInvoiceConfigurations = async () => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/all`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching invoiceConfigurations:", error);
    throw error;
  }
};

export const createInvoiceConfiguration = async (invoiceConfigurationData: IInvoiceConfiguration) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      invoiceConfigurationData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error creating invoiceConfiguration:", error);
    throw error;
  }
};
