import IFieldData from "@interfaces/IFieldData";
import { message } from "antd";

export interface IFormProps {
  dataObject?: any;
  onReload?: Function;
  onCancel?: Function;
  excludeFields?: boolean;
}

export interface IRenderItem {
  key: string;
  label: string;
  value: string;
}

export const onFinishFailed = () => {
  message.error("Llenar todos los campos requeridos!");
};

export const getFieldData = (fieldData: any): IFieldData[] =>
  Object.entries(fieldData)?.map(([key, value]: any) => ({
    name: key,
    value: value?.key ? value.key : value,
  }));

export const filterData = (inputValue: any, option: any) => {
  const labelMatch = option.label.toUpperCase().includes(inputValue.toUpperCase());
  const phoneMatch = option.phone ? option.phone.toUpperCase().includes(inputValue.toUpperCase()) : false;
  return labelMatch || phoneMatch;
};

export const renderItem = (value: any) => ({
  key: value.id,
  label: value.name,
  value: value.id,
});

export const userItem = (value: any) => ({
  key: value.id,
  label: value.name,
  value: value.id,
  name: value.name,
  dni: value.dni,
  rtn: value.rtn,
  phone: `${value.phonePrefix} ${value.phone}`,
});

export const companyItem = (value: any) => ({
  key: value.id,
  label: value.name,
  value: value.id,
  name: value.name,
  phone: `${value.phonePrefix} ${value.phone}`,
});

export const invoiceConfigurationItem = (value: any) => ({
  key: value.id,
  label: value.name,
  value: value.id,
  totalNumeration: value.totalNumeration,
  currentNumeration: value.currentNumeration,
  deadlineForIssuance: value.deadlineForIssuance,
});
