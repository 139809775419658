import axios from "axios";
import IService from "@interfaces/IService";

import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/service";

export const getServices = async () => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/all`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching services:", error);
    throw error;
  }
};

export const createService = async (serviceData: IService) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      serviceData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error creating service:", error);
    throw error;
  }
};

export const updateService = async (serviceData: IService) => {
  try {
    const { id } = serviceData;
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/${id}`,
      serviceData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error updating service:", error);
    throw error;
  }
};
