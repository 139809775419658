import React, { useState } from "react";
import { Button, Modal, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { EditFilled, DeleteOutlined, DollarOutlined } from "@ant-design/icons";

import CheckinComponent from "../CheckinComponent";

export interface IActionProps {
  label: string;
  dataObject: any;
  onDelete?: Function;
  onReload?: Function;
  onEdit?: Function;
  onPayment?: Function;
}

const ActionComponent: React.FC<IActionProps> = (props) => {
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onEdit = () => {
    props.onEdit
      ? props.onEdit()
      : navigate("form", {
          state: { dataObject: props.dataObject },
        });
  };

  const onPayment = () => {
    props.onPayment
      ? props.onPayment()
      : navigate("reservationInvoice", {
          state: { dataObject: props.dataObject },
        });
  };

  const onDelete = () => {
    props.onDelete && props.onDelete(props.dataObject);
    setConfirmOpen(false);
  };

  const onReload = () => props.onReload && props.onReload(props.dataObject);
  return (
    <>
      <Tooltip title="Editar">
        <Button
          ghost
          className="ghost-button"
          icon={<EditFilled onClick={() => onEdit()} />}
        />
      </Tooltip>
      <CheckinComponent dataObject={props.dataObject} onReload={onReload} />
      <Tooltip title="Pagar">
        <Button
          ghost
          className="ghost-button"
          icon={<DollarOutlined onClick={() => onPayment()} />}
        />
      </Tooltip>
      <Tooltip title="Borrar">
        <Button
          ghost
          className="ghost-button"
          icon={<DeleteOutlined onClick={() => setConfirmOpen(true)} />}
        />
      </Tooltip>

      <Modal
        title={`Borrar`}
        open={confirmOpen}
        centered
        okText="Confirmar"
        cancelText="Cancelar"
        onOk={() => onDelete()}
        onCancel={() => setConfirmOpen(false)}
      >
        <p>Esta seguro de borrar {props.label}?</p>
      </Modal>
    </>
  );
};

export default ActionComponent;
