import React, { Key, useEffect, useState } from "react";

import { Moment } from "moment";
import { getErrorMessage } from "@utils/errorMessage";
import { SorterResult } from "antd/es/table/interface";
import { getStatusColor } from "@components/Table/table";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Row, Col, Tag, Card, Table, Layout, message } from "antd";
import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { getCurrencyFormat, isFilterMatched, sortAlphaNum } from "@utils/util";

import {
  getInactiveReservations,
  getReservationDetailsByReservationId,
} from "@services/reservationService";

import {
  getToday,
  getDateString,
  getTimeString,
  getNumberOfDays,
  getDateDifference,
  getShortDateTimeString,
} from "@utils/date";

import IReservation from "@interfaces/IReservation";
import DateToolbar from "@components/Table/DateToolbar";
import TableToolbar from "@components/Table/TableToolbar";
import IReservationProduct from "@interfaces/IReservationProduct";
import IReservationService from "@interfaces/IReservationService";
import IReservationRoomsPayment from "@interfaces/IReservationRoomsPayment";

const { Header, Content } = Layout;

const ReservationHistory: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IReservation[]>([]);
  const [filteredString, setFilteredString] = useState<Key>("");
  const [selectedRowKey, setSelectedRowKey] = useState<any>([]);
  const [filteredDate, setFilteredDate] = useState<any>(getToday());
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IReservation>>({});

  const [selectedRowRoomsPayments, setSelectedRowRoomsPayments] = useState<
    IReservationRoomsPayment[]
  >([]);
  const [selectedRowProducts, setSelectedRowProducts] = useState<
    IReservationProduct[]
  >([]);
  const [selectedRowServices, setSelectedRowServices] = useState<
    IReservationService[]
  >([]);

  useEffect(() => {
    fetchData(filteredDate);
  }, [filteredDate]);

  const fetchData = async (filteredDate: Moment) => {
    try {
      setLoading(true);
      const inactiveReservationsData = await getInactiveReservations(
        filteredDate
      );
      setData(inactiveReservationsData);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<IReservation> = [
    {
      key: "initialDate",
      title: "Fecha de llegada",
      dataIndex: "initialDate",
      align: "center",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => getDateDifference(a.initialDate, b.initialDate),
      sortOrder:
        sortedInfo.columnKey === "initialDate" ? sortedInfo.order : null,
      render: (initialDate) => getDateString(initialDate),
    },
    {
      key: "finalDate",
      title: "Fecha de salida",
      dataIndex: "finalDate",
      align: "center",
      sorter: (a, b) => getDateDifference(a.finalDate, b.finalDate),
      sortOrder: sortedInfo.columnKey === "finalDate" ? sortedInfo.order : null,
      render: (finalDate) => getDateString(finalDate),
    },
    {
      key: "arrivalTime",
      title: "Hora de llegada",
      dataIndex: "arrivalTime",
      align: "center",
      sorter: (a, b) => getDateDifference(a.arrivalTime, b.arrivalTime),
      sortOrder:
        sortedInfo.columnKey === "arrivalTime" ? sortedInfo.order : null,
      render: (arrivalTime) => getTimeString(arrivalTime),
    },
    {
      key: "userName",
      title: "Cliente",
      dataIndex: "userName",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.userName, b.userName),
      sortOrder: sortedInfo.columnKey === "userName" ? sortedInfo.order : null,
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.status, b.status),
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
  ];

  const handleTableChange: TableProps<IReservation>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IReservation>);
  };

  const expandableComponent = {
    expandedRowKeys: selectedRowKey,
    onExpand: async (expanded: boolean, record: any) => {
      if (expanded) {
        const { products, services, roomsPayments } =
          await getReservationDetailsByReservationId(record.id);
        setSelectedRowProducts(products);
        setSelectedRowServices(services);
        setSelectedRowRoomsPayments(roomsPayments);
        setSelectedRowKey([record.id]);
      } else {
        setSelectedRowKey([]);
      }
    },
    expandedRowRender: (record: any) => (
      <>
        <Card className="mb" title="Detalles">
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Huespedes </b>
            <br />
            <span>{record.guests}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Noches </b>
            <br />
            <span>{getNumberOfDays(record.initialDate, record.finalDate)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Checkin </b>
            <br />
            <span>{getShortDateTimeString(record.checkinAt)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Checkout </b>
            <br />
            <span>{getShortDateTimeString(record.checkoutAt)}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>D.N.I. Cliente </b>
            <br />
            <span>{record.userDni}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>R.T.N. Cliente </b>
            <br />
            <span>{record.userRtn}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Teléfono Cliente</b>
            <br />
            <span>
              {record.userPhonePrefix} {record.userPhone}
            </span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail"></Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Nombre Empresa </b>
            <br />
            <span>{record.companyName}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>R.T.N. Empresa </b>
            <br />
            <span>{record.companyRtn}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Teléfono Empresa</b>
            <br />
            <span>
              {record.companyPhonePrefix} {record.companyPhone}
            </span>
          </Card.Grid>
        </Card>
        {[
          ...selectedRowProducts,
          ...selectedRowServices,
          ...selectedRowRoomsPayments,
        ].length > 0 && (
          <Card className="mb" title="Pagos">
            {[
              ...selectedRowProducts,
              ...selectedRowServices,
              ...selectedRowRoomsPayments,
            ]?.map((payment: any) => (
              <Card.Grid
                key={payment.id}
                hoverable={false}
                className="table-row-detail"
              >
                <b>{payment.name}</b>
                <br />
                <b>Precio:</b> <span>L.{getCurrencyFormat(payment.price)}</span>
                <br />
                {payment.date ? (
                  <>
                    <span>{payment.date}</span>
                    <br />
                  </>
                ) : (
                  <>
                    <b>Cantidad:</b> <span>{payment.quantity}</span>
                    <br />
                  </>
                )}
                <br />
                {payment.invoiceIds ? (
                  <CheckCircleFilled
                    style={{
                      color: "green",
                      fontSize: "32px",
                    }}
                  />
                ) : (
                  <MinusCircleFilled
                    style={{
                      color: "red",
                      fontSize: "32px",
                    }}
                  />
                )}
              </Card.Grid>
            ))}
          </Card>
        )}
        {record.notes && (
          <Card className="mb" title="Notas">
            <Card.Grid hoverable={false} className="table-row-detail-single ">
              <span>{record.notes}</span>
            </Card.Grid>
          </Card>
        )}
        <Card className="mb" title="Historial de Cambios">
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Creado Por </b>
            <br />
            <span>{record.createdBy}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Modificado Por </b>
            <br />
            <span>{record.modifiedBy}</span>
          </Card.Grid>
        </Card>
      </>
    ),
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <Row>
            <Col style={{ display: "flex" }} flex={0.5}>
              <DateToolbar
                filteredDate={filteredDate}
                setFilteredDate={setFilteredDate}
              />
            </Col>
            <Col style={{ display: "flex", flexDirection: "column" }} flex={12}>
              <TableToolbar
                onFilterChange={setFilteredString}
                canCreate={false}
              />
            </Col>
          </Row>
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            expandable={expandableComponent}
            pagination={{ pageSize: 20 }}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default ReservationHistory;
