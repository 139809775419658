import React, { Key, useEffect, useState } from "react";
import { getInvoiceConfigurations } from "@services/invoiceConfigurationService";
import { Table, Layout, Card, message } from "antd";
import { getErrorMessage } from "@utils/errorMessage";
import { SorterResult } from "antd/es/table/interface";
import { isFilterMatched, sortAlphaNum } from "@utils/util";
import type { ColumnsType, TableProps } from "antd/es/table";

import IInvoiceConfiguration from "@interfaces/IInvoiceConfiguration";
import TableToolbar from "@components/Table/TableToolbar";
import { getDateDifference, getDateString } from "@utils/date";

const { Header, Content } = Layout;

const InvoiceConfigurationTable = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IInvoiceConfiguration[]>([]);
  const [filteredString, setFilteredString] = useState<Key>("");
  const [sortedInfo, setSortedInfo] = useState<
    SorterResult<IInvoiceConfiguration>
  >({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const invoiceConfigurationsData = await getInvoiceConfigurations();
      setData(invoiceConfigurationsData);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<IInvoiceConfiguration> = [
    {
      key: "name",
      title: "Nombre",
      dataIndex: "name",
      align: "center",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => sortAlphaNum(a.name, b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      key: "cai",
      title: "CAI",
      dataIndex: "cai",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.cai, b.cai),
      sortOrder: sortedInfo.columnKey === "cai" ? sortedInfo.order : null,
    },
    {
      key: "deadlineForIssuance",
      title: "Fecha limite emisión",
      dataIndex: "deadlineForIssuance",
      align: "center",
      sorter: (a, b) =>
        getDateDifference(a.deadlineForIssuance, b.deadlineForIssuance),
      sortOrder:
        sortedInfo.columnKey === "deadlineForIssuance"
          ? sortedInfo.order
          : null,
      render: (deadlineForIssuance) => getDateString(deadlineForIssuance),
    },
    {
      key: "initialAuthorizedRange",
      title: "Rango inicial autorizado",
      dataIndex: "initialAuthorizedRange",
      align: "center",
      sorter: (a, b) =>
        sortAlphaNum(a.initialAuthorizedRange, b.initialAuthorizedRange),
      sortOrder:
        sortedInfo.columnKey === "initialAuthorizedRange"
          ? sortedInfo.order
          : null,
    },
    {
      key: "finalAuthorizedRange",
      title: "Rango final autorizado",
      dataIndex: "finalAuthorizedRange",
      align: "center",
      sorter: (a, b) =>
        sortAlphaNum(a.finalAuthorizedRange, b.finalAuthorizedRange),
      sortOrder:
        sortedInfo.columnKey === "finalAuthorizedRange"
          ? sortedInfo.order
          : null,
    },
    {
      key: "currentNumeration",
      title: "Numeración actual",
      dataIndex: "currentNumeration",
      align: "center",
      sorter: (a, b) => a.currentNumeration - b.currentNumeration,
      sortOrder:
        sortedInfo.columnKey === "currentNumeration" ? sortedInfo.order : null,
    },
    {
      key: "totalNumeration",
      title: "Numeración total",
      dataIndex: "totalNumeration",
      align: "center",
      sorter: (a, b) => a.totalNumeration - b.totalNumeration,
      sortOrder:
        sortedInfo.columnKey === "totalNumeration" ? sortedInfo.order : null,
    },
  ];

  const handleTableChange: TableProps<IInvoiceConfiguration>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IInvoiceConfiguration>);
  };

  const handleFilterChange = (value: string) => {
    setFilteredString(value);
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={handleFilterChange} canCreate />
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            pagination={{ pageSize: 20 }}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default InvoiceConfigurationTable;
