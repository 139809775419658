import IUser from "@interfaces/IUser";
import ICompany from "@interfaces/ICompany";
import UserForm from "@pages/Users/UserForm";
import TimePicker from "@components/Common/TimePicker";
import DatePicker from "@components/Common/DatePicker";
import CompanyForm from "@pages/Companies/CompanyForm";

import { useEffect, useState } from "react";
import { getErrorMessage } from "@utils/errorMessage";
import { optionsRender } from "@components/Common/Render";
import { getUsersBasicInfo } from "@services/userService";
import { getCompaniesBasicInfo } from "@services/companyService";

import {
  userItem,
  filterData,
  IRenderItem,
  companyItem,
} from "@components/Form/form";

import {
  Row,
  Col,
  Form,
  Spin,
  Modal,
  Select,
  message,
  Button,
  InputNumber,
} from "antd";

const { RangePicker } = DatePicker;

export interface IReservationDetailsProps {
  form: any;
}

const ReservationDetails: React.FC<IReservationDetailsProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);

  const [userOptions, setUserOptions] = useState<IRenderItem[]>([]);
  const [companyOptions, setCompanyOptions] = useState<IRenderItem[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchUsersBasicInfoData();
    fetchCompaniesBasicInfoData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsersBasicInfoData = async () => {
    try {
      const users = await getUsersBasicInfo();
      const userOptions: IRenderItem[] = users?.map((user: IUser) =>
        userItem(user)
      );
      setUserOptions(userOptions);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return [];
    }
  };

  const fetchCompaniesBasicInfoData = async () => {
    try {
      const companies = await getCompaniesBasicInfo();
      const companyOptions: IRenderItem[] = companies?.map(
        (company: ICompany) => companyItem(company)
      );
      setCompanyOptions(companyOptions);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return [];
    }
  };

  const onUserReload = async (userId: string = "") => {
    setLoading(true);
    await fetchUsersBasicInfoData();
    props.form.setFieldsValue({ userId });
    setLoading(false);
    onModalClose();
  };

  const onCompanyReload = async (companyId: string = "") => {
    setLoading(true);
    await fetchCompaniesBasicInfoData();
    props.form.setFieldsValue({
      companyId,
    });
    setLoading(false);
    onModalClose();
  };

  const onModalClose = async () => {
    setUserModalOpen(false);
    setCompanyModalOpen(false);
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <div style={{ marginTop: 20 }}>
        <Form.Item name="id" hidden />
        <Form.Item name="status" hidden />

        <Form.Item name="dates" label="Fechas" rules={[{ required: true }]}>
          <RangePicker style={{ width: "100%" }} format={"ddd DD/MM/YY"} />
        </Form.Item>

        <Form.Item
          name="arrivalTime"
          label="Hora de llegada"
          rules={[{ required: true }]}
        >
          <TimePicker style={{ width: "100%" }} use12Hours format="h:mm a" />
        </Form.Item>

        <Form.Item
          name="guests"
          label="Huespedes"
          initialValue={2}
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: "100%" }} controls={true} min={1} />
        </Form.Item>

        <Form.Item name="userId" label="Cliente" rules={[{ required: true }]}>
          <Row>
            <Col span={18}>
              <Form.Item name="userId" noStyle>
                <Select
                  showSearch
                  options={userOptions}
                  style={{ width: "100%" }}
                  filterOption={filterData}
                  optionRender={optionsRender}
                  notFoundContent="Usuario no encontrado"
                ></Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Button type="link" onClick={() => setUserModalOpen(true)}>
                Crear Nuevo
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item name="companyId" label="Empresa">
          <Row>
            <Col span={18}>
              <Form.Item name="companyId" noStyle>
                <Select
                  showSearch
                  options={companyOptions}
                  style={{ width: "100%" }}
                  filterOption={filterData}
                  optionRender={optionsRender}
                  notFoundContent="Empresa no encontrada"
                ></Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button type="link" onClick={() => setCompanyModalOpen(true)}>
                Crear Nuevo
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </div>
      {userModalOpen && (
        <Modal
          title="Crear Cliente"
          open={userModalOpen}
          centered
          width={700}
          footer={[]}
          onCancel={() => onModalClose()}
        >
          <UserForm
            excludeFields
            onReload={(userId: string) => onUserReload(userId)}
            onCancel={() => onModalClose()}
          />
        </Modal>
      )}

      {companyModalOpen && (
        <Modal
          title="Crear empresa"
          open={companyModalOpen}
          centered
          width={700}
          footer={[]}
          onCancel={() => onModalClose()}
        >
          <CompanyForm
            excludeFields
            dataObject={{}}
            onReload={onCompanyReload}
            onCancel={() => onModalClose()}
          />
        </Modal>
      )}
    </Spin>
  );
};

export default ReservationDetails;
