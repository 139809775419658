import { useEffect, useState } from "react";
import { getCurrencyFormat } from "@utils/util";
import { connectAndPrint } from "@utils/printer";
import Table, { ColumnsType } from "antd/es/table";
import { getErrorMessage } from "@utils/errorMessage";
import { paymentMethod } from "@constants/paymentMethod";
import { createInvoice } from "@services/invoiceService";
import { getUsersBasicInfo } from "@services/userService";
import { paymentFormLayout } from "@constants/formLayout";
import { PRIMARY_COLOR } from "@constants/globalConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { getSpan, optionsRender } from "@components/Common/Render";
import { isInvoiceDateValid, isInvoiceDateAboutToExpire } from "@utils/date";
import { getInvoiceConfigurations } from "@services/invoiceConfigurationService";

import {
  getReservationProductsByReservationId,
  getReservationRoomsPaymentsByReservationId,
  getReservationServicesByReservationId,
} from "@services/reservationService";

import {
  PlusOutlined,
  WarningOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import {
  userItem,
  filterData,
  IRenderItem,
  onFinishFailed,
  invoiceConfigurationItem,
} from "@components/Form/form";

import {
  Col,
  Row,
  Form,
  Card,
  Spin,
  Modal,
  Space,
  Input,
  Button,
  Select,
  message,
  Divider,
  InputNumber,
} from "antd";

import IUser from "@interfaces/IUser";
import IInvoice from "@interfaces/IInvoice";
import IReservation from "@interfaces/IReservation";
import IReservationProduct from "@interfaces/IReservationProduct";
import IReservationService from "@interfaces/IReservationService";
import IInvoiceConfiguration from "@interfaces/IInvoiceConfiguration";
import IReservationRoomsPayment from "@interfaces/IReservationRoomsPayment";

const { TextArea } = Input;

const ReservationInvoice: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();

  const reservation: IReservation = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [isvTax, setIsvTax] = useState<number>(0);
  const [isrtTax, setIsrtTax] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [invoices, setInvoices] = useState<IInvoice[]>();
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [subtotalWithDiscount, setSubtotalWithDiscount] = useState<number>(0);

  const [reservationRoomsPayments, setRoomsPaymentsData] = useState<
    IReservationRoomsPayment[]
  >([]);
  const [reservationProductsPayments, setProductsPaymentsData] = useState<
    IReservationProduct[]
  >([]);
  const [reservationServicesPayments, setServicesPaymentsData] = useState<
    IReservationService[]
  >([]);
  const [invoiceConfigurationsOptions, setInvoiceConfigurationsOptions] =
    useState<IInvoiceConfiguration[]>([]);
  const [selectedReservationRoomPayments, setSelectedRoomPayments] =
    useState<any>([]);
  const [selectedReservationProductsPayments, setSelectedProductPayments] =
    useState<any>([]);
  const [selectedReservationServicesPayments, setSelectedServicePayments] =
    useState<any>([]);

  useEffect(() => {
    setLoading(true);
    fetchUsersBasicInfoData();
    fetchInvoiceConfigurationsData();
    fetchReservationProductsData();
    fetchReservationServicesData();
    fetchReservationRoomsPaymentsData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation]);

  useEffect(() => {
    setLoading(true);
    const roomsPaymentsSubtotal = selectedReservationRoomPayments?.reduce(
      (accumulator: number, item: IReservationRoomsPayment) => {
        return accumulator + Number(item.price);
      },
      0
    );

    const productsSubtotal = selectedReservationProductsPayments?.reduce(
      (accumulator: number, item: IReservationProduct) => {
        return accumulator + Number(item.price) * Number(item.quantity);
      },
      0
    );

    const servicesSubtotal = selectedReservationServicesPayments?.reduce(
      (accumulator: number, item: IReservationService) => {
        return accumulator + Number(item.price) * Number(item.quantity);
      },
      0
    );

    const discountDecimal = discountPercentage / 100 || 0;

    const roomsDiscount = roomsPaymentsSubtotal * discountDecimal;
    const productsDiscount = productsSubtotal * discountDecimal;
    const servicesDiscount = servicesSubtotal * discountDecimal;

    const roomsPaymentsSubtotalWithDiscount =
      roomsPaymentsSubtotal - roomsDiscount;
    const productsSubtotalWithDiscount = productsSubtotal - productsDiscount;
    const servicesSubtotalWithDiscount = servicesSubtotal - servicesDiscount;

    const subtotal =
      roomsPaymentsSubtotal + productsSubtotal + servicesSubtotal;
    const subtotalWithDiscount =
      roomsPaymentsSubtotalWithDiscount +
      productsSubtotalWithDiscount +
      servicesSubtotalWithDiscount;
    const isvTax = subtotalWithDiscount * 0.15;
    const isrtTax = roomsPaymentsSubtotalWithDiscount * 0.04;
    const total = subtotalWithDiscount + isvTax + isrtTax;

    setDiscount(roomsDiscount + productsDiscount);
    setIsvTax(isvTax);
    setIsrtTax(isrtTax);
    setSubtotal(subtotal);
    setSubtotalWithDiscount(subtotalWithDiscount);
    setTotal(total);
    setLoading(false);
  }, [
    selectedReservationRoomPayments,
    selectedReservationProductsPayments,
    selectedReservationServicesPayments,
    discountPercentage,
  ]);

  const fetchUsersBasicInfoData = async () => {
    try {
      const users = await getUsersBasicInfo();
      const userOptions: IRenderItem[] = users?.map((user: IUser) =>
        userItem(user)
      );
      setUserOptions(userOptions);
      return userOptions;
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return [];
    }
  };

  const fetchReservationRoomsPaymentsData = async () => {
    try {
      const reservationRoomsPaymentsData =
        await getReservationRoomsPaymentsByReservationId(reservation.id);
      setRoomsPaymentsData(
        reservationRoomsPaymentsData.filter(
          (x: IReservationRoomsPayment) => !x.invoiceIds
        )
      );
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    }
  };

  const fetchReservationProductsData = async () => {
    try {
      const reservationProductsPaymentsData =
        await getReservationProductsByReservationId(reservation.id);
      setProductsPaymentsData(
        reservationProductsPaymentsData.filter(
          (x: IReservationProduct) => !x.invoiceIds
        )
      );
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    }
  };

  const fetchReservationServicesData = async () => {
    try {
      const reservationServicesPaymentsData =
        await getReservationServicesByReservationId(reservation.id);
      setServicesPaymentsData(
        reservationServicesPaymentsData.filter(
          (x: IReservationService) => !x.invoiceIds
        )
      );
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    }
  };

  const fetchInvoiceConfigurationsData = async () => {
    try {
      const invoiceConfigurations = await getInvoiceConfigurations();

      const filteredInvoiceConfigurations = invoiceConfigurations?.filter(
        (x: IInvoiceConfiguration) => {
          const invoiceNumbers =
            Number(x.totalNumeration) - Number(x.currentNumeration);
          const limitDate = isInvoiceDateValid(x.deadlineForIssuance);
          return invoiceNumbers >= 0 && limitDate;
        }
      );

      const invoiceConfigurationsOptions = filteredInvoiceConfigurations?.map(
        (invoiceConfiguration: any) =>
          invoiceConfigurationItem(invoiceConfiguration)
      );
      setInvoiceConfigurationsOptions(invoiceConfigurationsOptions);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    }
  };

  const roomPaymentColumns: ColumnsType<IReservationRoomsPayment> = [
    {
      key: "name",
      title: "Nombre",
      dataIndex: "name",
    },
    {
      key: "price",
      title: "Precio",
      dataIndex: "price",
      render: (price: number) => `L. ${getCurrencyFormat(price)}`,
    },
    {
      key: "date",
      title: "Fecha",
      dataIndex: "date",
      align: "center",
    },
  ];

  const productPaymentColumns: ColumnsType<IReservationProduct> = [
    {
      key: "name",
      title: "Nombre",
      dataIndex: "name",
    },
    {
      key: "price",
      title: "Precio",
      dataIndex: "price",
      render: (price: number) => `L. ${getCurrencyFormat(price)}`,
    },
    {
      key: "quantity",
      title: "Cantidad",
      dataIndex: "quantity",
    },
  ];

  const servicePaymentColumns: ColumnsType<IReservationService> = [
    {
      key: "name",
      title: "Nombre",
      dataIndex: "name",
    },
    {
      key: "price",
      title: "Precio",
      dataIndex: "price",
      render: (price: number) => `L. ${getCurrencyFormat(price)}`,
    },
    {
      key: "quantity",
      title: "Cantidad",
      dataIndex: "quantity",
    },
  ];

  const roomsRowSelection = {
    onChange: (_: any, selectedRows: any[]) => {
      setSelectedRoomPayments(selectedRows);
    },
  };

  const productsRowSelection = {
    onChange: (_: any, selectedRows: any[]) => {
      setSelectedProductPayments(selectedRows);
    },
  };

  const servicesRowSelection = {
    onChange: (_: any, selectedRows: any[]) => {
      setSelectedServicePayments(selectedRows);
    },
  };

  const goBack = () => (props.onReload ? props.onReload() : navigate(".."));
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (data: IInvoice) => {
    try {
      setLoading(true);
      const reservationRoomsPaymentsIds = selectedReservationRoomPayments.map(
        (x: IReservationRoomsPayment) => x.id
      );
      const reservationProductsIds = selectedReservationProductsPayments.map(
        (x: IReservationProduct) => x.id
      );
      const reservationServicesIds = selectedReservationServicesPayments.map(
        (x: IReservationService) => x.id
      );
      const invoiceObject = Object.assign({}, data, {
        reservationId: reservation.id,
        reservationRoomsPaymentsIds,
        reservationProductsIds,
        reservationServicesIds,
        invoiceCompanyName: reservation.companyName || null,
        invoiceCompanyRtn: reservation.companyRtn || null,
      });
      const invoices = await createInvoice(invoiceObject);
      setInvoices(invoices);
      message.success("Factura creada!");
      setShowInvoiceModal(true);
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const shouldDisableSubmitButton = () =>
    selectedReservationRoomPayments.length === 0 &&
    selectedReservationProductsPayments.length === 0 &&
    selectedReservationServicesPayments.length === 0;

  const printInvoice = async () => {
    try {
      if (invoices && invoices.length > 0) {
        setLoading(true);
        await connectAndPrint(invoices);
      }
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
      handleCancel();
    }
  };

  const getInvoicesExpireMessage = () => {
    const isAboutToExpire = invoiceConfigurationsOptions.filter((x: any) => {
      const invoiceNumbers =
        Number(x.totalNumeration) - Number(x.currentNumeration);
      const limitDate = isInvoiceDateAboutToExpire(x.deadlineForIssuance);
      return invoiceNumbers <= 50 || limitDate;
    });

    return isAboutToExpire?.map((x: any) => (
      <p style={{ color: "red" }}>
        <WarningOutlined /> {x.label} esta cerca de expirar.
      </p>
    ));
  };

  const onFieldsChangeForm = (changedFields: any) => {
    if (changedFields.length !== 1) return;
    const [field] = changedFields;
    const [name] = field.name;
    const property = field.name[2];
    if (name === "invoiceClients" && property === "clientId") {
      onUserChange(name, field);
    }
    if (name === "discountPercentage") {
      setDiscountPercentage(field.value);
    }
  };

  const onUserChange = (name: string, field: any) => {
    const index = field.name[1];
    const key = field.name[2];
    if (name !== "invoiceClients" || key !== "clientId") return;

    const user = userOptions?.find((x: any) => x.key === field.value);
    const clientName = user.name || "";
    const clientDni = user.dni || "";
    const clientRtn = user.rtn || "";

    const usersField = form.getFieldValue("invoiceClients");
    usersField[index].clientName = clientName;
    usersField[index].clientDni = clientDni;
    usersField[index].clientRtn = clientRtn;
    form.setFieldValue("invoiceClients", usersField);
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card bordered={false}>
        <Row>
          <Col span={8} style={{ paddingRight: 30 }}>
            <h2>Seleccionar pagos</h2>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Row>
              <Col span={24}>
                <Table
                  rowKey={(record, index) =>
                    `${record.reservationId}-${record.roomId}-${index}`
                  }
                  title={() => <h3 className="table-header">Habitaciones</h3>}
                  pagination={false}
                  loading={loading}
                  columns={roomPaymentColumns}
                  rowSelection={roomsRowSelection}
                  dataSource={reservationRoomsPayments}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                <Table
                  rowKey={(record, index) =>
                    `${record.reservationId}-${record.productId}-${index}`
                  }
                  title={() => <h3 className="table-header">Productos</h3>}
                  pagination={false}
                  loading={loading}
                  columns={productPaymentColumns}
                  rowSelection={productsRowSelection}
                  dataSource={reservationProductsPayments}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                <Table
                  rowKey={(record, index) =>
                    `${record.reservationId}-${record.serviceId}-${index}`
                  }
                  title={() => <h3 className="table-header">Servicios</h3>}
                  pagination={false}
                  loading={loading}
                  columns={servicePaymentColumns}
                  rowSelection={servicesRowSelection}
                  dataSource={reservationServicesPayments}
                />
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ paddingRight: 30 }}>
            <h2>Clientes</h2>
            <Form
              form={form}
              onFinish={onFinish}
              {...paymentFormLayout}
              onFinishFailed={onFinishFailed}
              onFieldsChange={onFieldsChangeForm}
            >
              <Form.List
                name="invoiceClients"
                initialValue={[
                  {
                    clientId: reservation.userId,
                    clientName: reservation.userName,
                    clientDni: reservation.userDni,
                    clientRtn: reservation.userRtn,
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <Divider style={{ marginTop: 0 }} />
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, "clientId"]}
                          label={getSpan("Cliente")}
                          rules={[{ required: true }]}
                        >
                          <Select
                            size="large"
                            showSearch
                            options={userOptions}
                            notFoundContent="Usuario no encontrado"
                            filterOption={filterData}
                            optionRender={optionsRender}
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "paymentMethod"]}
                          label={getSpan("Método de pago")}
                          rules={[{ required: true }]}
                          initialValue={paymentMethod.CASH}
                        >
                          <Select size="large">
                            <Select.Option value={paymentMethod.CASH}>
                              {paymentMethod.CASH}
                            </Select.Option>
                            <Select.Option value={paymentMethod.TRANSFER}>
                              {paymentMethod.TRANSFER}
                            </Select.Option>
                            <Select.Option value={paymentMethod.CREDIT_CARD}>
                              {paymentMethod.CREDIT_CARD}
                            </Select.Option>
                            <Select.Option value={paymentMethod.CREDIT}>
                              {paymentMethod.CREDIT}
                            </Select.Option>
                            <Select.Option value={paymentMethod.PAYMENT_LINK}>
                              {paymentMethod.PAYMENT_LINK}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "clientDni"]}
                          label={getSpan("D.N.I.")}
                        >
                          <Input size="large" maxLength={50} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "clientRtn"]}
                          label={getSpan("R.T.N.")}
                        >
                          <Input size="large" maxLength={50} />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ marginBottom: 20 }}
                            className="dynamic-delete-button"
                            onClick={() => remove(name)}
                          />
                        ) : null}
                        <Divider style={{ marginTop: 0 }} />
                      </div>
                    ))}
                    {fields.length < 4 ? (
                      <Form.Item style={{ marginBottom: 20 }}>
                        <Button
                          block
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                          style={{
                            color: PRIMARY_COLOR,
                            borderColor: PRIMARY_COLOR,
                          }}
                        >
                          Agregar cliente
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    ) : null}
                  </>
                )}
              </Form.List>
            </Form>
          </Col>

          <Col span={8}>
            <h2>Detalle de factura</h2>
            <Divider style={{ marginTop: 0 }} />
            <Form
              form={form}
              onFinish={onFinish}
              {...paymentFormLayout}
              onFinishFailed={onFinishFailed}
              onFieldsChange={onFieldsChangeForm}
            >
              <Form.Item
                name="invoiceConfigurationId"
                rules={[{ required: true }]}
                label={getSpan("Tipo de Factura")}
              >
                <Select
                  size="large"
                  options={invoiceConfigurationsOptions}
                  style={{
                    width: "100%",
                  }}
                ></Select>
              </Form.Item>

              <Form.Item
                initialValue={0}
                name="discountPercentage"
                rules={[{ required: true }]}
                label={getSpan("Descuento Porcentual")}
              >
                <InputNumber
                  min={0}
                  max={50}
                  size="large"
                  controls={true}
                  addonAfter="%"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>

              {reservation.companyName && (
                <>
                  <Form.Item
                    label={getSpan("Empresa")}
                    name={"invoiceCompanyName"}
                  >
                    {getSpan(reservation.companyName)}
                  </Form.Item>
                  <Form.Item
                    label={getSpan("R.T.N.")}
                    name={"invoiceCompanyRtn"}
                  >
                    {getSpan(reservation.companyRtn)}
                  </Form.Item>
                </>
              )}
              <Form.Item
                label={getSpan("Subtotal")}
                style={{
                  textAlign: "right",
                }}
              >
                {getSpan(`L. ${getCurrencyFormat(subtotal)}`)}
              </Form.Item>
              <Form.Item
                label={getSpan("Descuento")}
                style={{
                  textAlign: "right",
                }}
              >
                {getSpan(`L. ${getCurrencyFormat(discount)}`)}
              </Form.Item>
              <Form.Item
                label={getSpan("Subtotal con Descuento")}
                style={{
                  textAlign: "right",
                }}
              >
                {getSpan(`L. ${getCurrencyFormat(subtotalWithDiscount)}`)}
              </Form.Item>
              <Form.Item
                label={getSpan("I.S.V.")}
                style={{
                  textAlign: "right",
                }}
              >
                {getSpan(`L. ${getCurrencyFormat(isvTax)}`)}
              </Form.Item>
              <Form.Item
                label={getSpan("I.S.R.T.")}
                style={{
                  textAlign: "right",
                }}
              >
                {getSpan(`L. ${getCurrencyFormat(isrtTax)}`)}
              </Form.Item>
              <Form.Item
                label={getSpan("Total")}
                style={{
                  textAlign: "right",
                }}
              >
                {getSpan(`L. ${getCurrencyFormat(total)}`)}
              </Form.Item>
              {getSpan("Notas adicionales")}
              <Form.Item noStyle name="invoiceNotes">
                <TextArea
                  rows={5}
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item noStyle>
                <Space size="large" style={{ marginTop: 20 }}>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    disabled={shouldDisableSubmitButton()}
                  >
                    Crear Factura
                  </Button>
                  <Button size="large" onClick={() => handleCancel()}>
                    Cancelar
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
      <Modal
        title={`Factura Creada`}
        open={showInvoiceModal}
        centered
        okText="Imprimir Factura"
        cancelText="Regresar"
        onOk={() => printInvoice()}
        onCancel={() => handleCancel()}
      >
        <p>Desea imprimir la factura creada?</p>
        {getInvoicesExpireMessage()}
      </Modal>
    </Spin>
  );
};

export default ReservationInvoice;
