import React from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { Avatar, Menu, Modal, message } from "antd";

import {
  BankOutlined,
  ShopOutlined,
  TeamOutlined,
  RestOutlined,
  ToolOutlined,
  LogoutOutlined,
  HistoryOutlined,
  SettingOutlined,
  FileTextOutlined,
  CalendarOutlined,
  SolutionOutlined,
  BarChartOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

const MenuComponent: React.FC<any> = ({ user, isAdmin }) => {
  const navigate = useNavigate();
  const name = user.displayName;
  const initials = name?.match(/\b(\w)/g).join("") || "";
  const [{ confirm }, contextHolder] = Modal.useModal();

  const getReportChildren = () => {
    let items = [
      getItem(
        "Cierre de Caja",
        "cashRegisterClosingReport",
        <SolutionOutlined />
      ),
    ];
    if (isAdmin) {
      items = items.concat(
        getItem(
          "Ventas por Método de pago",
          "salesReportByPaymentMethod",
          <BarChartOutlined />
        ),
        getItem(
          "Ventas por Habitación",
          "salesReportByRooms",
          <BarChartOutlined />
        ),
        getItem(
          "Ventas por Tipos de Habitación",
          "salesReportByRoomTypes",
          <BarChartOutlined />
        ),
        getItem(
          "Ventas por Cantidad de Tipos de Habitación",
          "reportByRoomTypeQuantity",
          <BarChartOutlined />
        )
      );
    }
    return items;
  };

  const profile = {
    key: "profile",
    label: name,
    icon: (
      <Avatar className="avatar-class" shape="square" size={24}>
        {initials}
      </Avatar>
    ),
  };

  const maintenance = getItem("Mantenimiento", "456", <SettingOutlined />, [
    getItem("Usuarios", "users", <TeamOutlined />),
    getItem("Empresas", "companies", <BankOutlined />),
    getItem("Productos", "products", <RestOutlined />),
    getItem("Servicios", "services", <ToolOutlined />),
    getItem("Habitaciones", "rooms", <ShopOutlined />),
    getItem("Facturación", "invoiceConfiguration", <ReconciliationOutlined />),
  ]);

  const history = getItem("Historial", "789", <HistoryOutlined />, [
    getItem("Reservaciones", "reservationsHistory", <ContainerOutlined />),
    getItem("Facturas", "invoicesHistory", <FileTextOutlined />),
  ]);

  const items: any = [
    profile,
    getItem("Agenda", "timeline", <CalendarOutlined />),
    getItem("Reservaciones", "reservations", <ContainerOutlined />),
  ];

  const reports = getItem("Reportes", "123", <BarChartOutlined />, [
    ...getReportChildren(),
  ]);

  items.push(maintenance);
  items.push(history);
  items.push(reports);
  items.push(getItem("Logout", "logout", <LogoutOutlined />));

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: any[]
  ): any {
    return {
      key,
      icon,
      children,
      label,
    } as any;
  }

  const onMenuItemClick = (menu: any) => {
    if (menu.key === "profile") return;
    if (menu.key === "logout") {
      onConfirmLogout();
      return;
    }
    navigate(menu.key);
  };

  const onConfirmLogout = () => {
    confirm({
      title: `Logout`,
      centered: true,
      content: `Desea hacer logout?`,
      okText: "Logout",
      okType: "primary",
      cancelText: "No",
      onOk() {
        onLogout();
      },
    });
  };

  const onLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      navigate("/");
    } catch (error: any) {
      message.error("Error signing out:", error);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      {contextHolder}
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        style={{
          height: "100%",
          borderRight: 0,
          justifyContent: "space-between",
        }}
        items={items}
        onClick={onMenuItemClick}
      />
    </div>
  );
};

export default MenuComponent;
