import axios from "axios";
import MenuComponent from "@components/Menu";
import packageInfo from "../../../package.json";

import React, { useEffect, useState } from "react";
import { ROLE } from "@constants/role";
import { verifyAuth } from "@services/authService";
import { getErrorMessage } from "@utils/errorMessage";
import { breadcrumbNameMap } from "@constants/breadcrumbMap";
import { Breadcrumb, FloatButton, Layout, message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PublicContent, PrivateContent } from "@components/Content";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

import {
  TeamOutlined,
  BankOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

const { Content, Footer, Sider } = Layout;

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState<any>(false);
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const currentPath = location.pathname;
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const isForm = url.includes("form");
    let prefix = "";
    if (isForm) {
      prefix = location.state ? "Editar " : "Crear ";
    }

    const label = prefix + breadcrumbNameMap[url];
    return {
      key: url,
      title: currentPath === url ? label : <Link to={url}>{label}</Link>,
    };
  });

  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          const auth = getAuth();
          await signOut(auth);
          navigate("/");
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const token = await user.getIdTokenResult();
          if (
            token &&
            token.claims.role !== ROLE.ADMIN &&
            token.claims.role !== ROLE.RECEPCIONIST
          ) {
            await signOut(auth);
            message.error("Permisos invalidos");
            setUser(null);
          } else {
            await verifyAuth();
            setIsAdmin(token.claims.role === ROLE.ADMIN);
            setUser(user);
          }
        } else {
          setUser(null);
        }
      } catch (error: any) {
        message.error(getErrorMessage(error));
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Layout>
      {user !== null ? (
        <Layout>
          <Sider
            width={250}
            collapsedWidth={50}
            collapsible
            trigger={
              isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />
            }
            onCollapse={(collapsed) => setIsCollapsed(collapsed)}
          >
            <MenuComponent user={user} isAdmin={isAdmin} />
          </Sider>
          <Layout>
            {location.pathname !== "/" && location.pathname !== "/timeline" && (
              <Breadcrumb
                className="breadcrumb-class"
                items={breadcrumbItems}
              ></Breadcrumb>
            )}
            <Content className="content-class">
              <PrivateContent isAdmin={isAdmin} />
            </Content>
          </Layout>

          <FloatButton.Group
            type="primary"
            shape="circle"
            open={true}
            closeIcon={false}
          >
            <FloatButton
              tooltip="Crear reservación"
              icon={<ContainerOutlined />}
            />
            <FloatButton tooltip="Crear usuario" icon={<TeamOutlined />} />
            <FloatButton tooltip="Crear empresa" icon={<BankOutlined />} />
          </FloatButton.Group>
        </Layout>
      ) : (
        <PublicContent />
      )}
      <Footer style={{ textAlign: "center" }}>
        Micro Hotel Rio de Piedras ©2023 created by Wasabi <br />
        version {packageInfo.version}
      </Footer>
    </Layout>
  );
};

export default App;
