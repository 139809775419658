export const MONTH_YEAR_FORMAT = "MMMM YYYY";
export const MONTH_FORMAT = "MM";
export const YEAR_FORMAT = "YYYY";
export const TIME_FORMAT = "h:mm a";
export const WEEK_FORMAT = "DD-MMM";
export const DATE_FORMAT = "DD/MM/YYYY";
export const CALENDAR_DAY_FORMAT = "DD";
export const CALENDAR_DATE_FORMAT = "ddd";
export const DATE_FORMAT_POSTGRES = "YYYY-MM-DD";
export const DATE_DETAIL_FORMAT = "DD MMMM YYYY";
export const DATE_TIME_FORMAT = "DD/MM/YYYY hh:mm A";
export const FULL_DAY_MONTH_FORMAT = "dddd DD [de] MMMM";
export const SHORT_DAY_MONTH_YEAR_FORMAT = "DD [de] MMMM YYYY";
export const SHORT_DAY_TIME_MONTH_YEAR_FORMAT = "DD [de] MMMM YYYY hh:mm A";
export const FULL_DAY_MONTH_YEAR_FORMAT = "dddd DD [de] MMMM YYYY";
