export const optionsRender = (option: any) => (
  <span>
    <b>{option.data.label}</b>
    <br />
    {option.data.phone}
  </span>
);

export const getSpan = (text: string) => (
  <span
    style={{
      fontSize: 16,
      fontWeight: "bold",
      color: "GrayText",
    }}
  >
    {text}
  </span>
);

export const getModalHeader = (message: string) => (
    <div className="info-modal">
      <div className="info-modal-title">
        <h2 style={{ marginTop: 0 }}>{message}</h2>
      </div>
    </div>
  );
