import { Form, Input } from "antd";

const { TextArea } = Input;

const NotesDetails: React.FC = () => (
  <Form.Item name="notes" noStyle>
    <TextArea style={{ width: "80%" }} rows={10} maxLength={500} />
  </Form.Item>
);

export default NotesDetails;
