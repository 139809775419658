import { paymentMethod } from "@constants/paymentMethod";
import { roomTypes } from "@constants/roomTypes";
import IRoom from "@interfaces/IRoom";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { getShortDateString, getTodayShort } from "@utils/date";
import { orderAlphaNumeric } from "@utils/util";
import { Card } from "antd";

export const chartAsImage = (chartRef: any) => {
  if (!chartRef) return null;
  const canvas = chartRef.canvas;
  return canvas.toDataURL("image/png");
};

export const ReportByPaymentMethod = ({
  chartRef,
  filteredDate,
  reportData,
}: any) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>Reporte de Ventas por Método de Pago</Text>
        <View style={styles.table}>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha de reporte</Text>
            <Text style={styles.subtitle}>{getTodayShort()}</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha inicial</Text>
            <Text style={styles.subtitle}>
              {getShortDateString(filteredDate[0])}
            </Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha final</Text>
            <Text style={styles.subtitle}>
              {getShortDateString(filteredDate[1])}
            </Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Total</Text>
            <Text style={styles.subtitle}>
              L. {sumTotalPrices(reportData)}
            </Text>
          </View>
        </View>
        <Image src={chartAsImage(chartRef)} style={styles.chartImage} />
      </View>
    </Page>
  </Document>
);

export const ReportByRooms = ({ chartRef, filteredDate, reportData }: any) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>Reporte de Ventas por Habitaciones</Text>
        <View style={styles.table}>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha de reporte</Text>
            <Text style={styles.subtitle}>{getTodayShort()}</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha inicial</Text>
            <Text style={styles.subtitle}>
              {getShortDateString(filteredDate[0])}
            </Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha final</Text>
            <Text style={styles.subtitle}>
              {getShortDateString(filteredDate[1])}
            </Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Total</Text>
            <Text style={styles.subtitle}>
              L. {sumTotalPrices(reportData)}
            </Text>
          </View>
        </View>
        <Image src={chartAsImage(chartRef)} style={styles.chartImage} />
      </View>
    </Page>
  </Document>
);

export const ReportByRoomsType = ({
  chartRef,
  filteredDate,
  reportData,
}: any) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>
          Reporte de Ventas por Tipo de Habitaciones
        </Text>
        <View style={styles.table}>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha de reporte</Text>
            <Text style={styles.subtitle}>{getTodayShort()}</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha inicial</Text>
            <Text style={styles.subtitle}>
              {getShortDateString(filteredDate[0])}
            </Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha final</Text>
            <Text style={styles.subtitle}>
              {getShortDateString(filteredDate[1])}
            </Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Total</Text>
            <Text style={styles.subtitle}>
              L. {sumTotalPrices(reportData)}
            </Text>
          </View>
        </View>
        <Image src={chartAsImage(chartRef)} style={styles.chartImage} />
      </View>
    </Page>
  </Document>
);

export const ReportByRoomsTypeQuantity = ({
  chartRef,
  filteredDate,
  reportData,
}: any) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>
          Reporte de Cantidad de Tipo de Habitaciones Vendidas
        </Text>
        <View style={styles.table}>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha de reporte</Text>
            <Text style={styles.subtitle}>{getTodayShort()}</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha inicial</Text>
            <Text style={styles.subtitle}>
              {getShortDateString(filteredDate[0])}
            </Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Fecha final</Text>
            <Text style={styles.subtitle}>
              {getShortDateString(filteredDate[1])}
            </Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.title}>Total</Text>
            <Text style={styles.subtitle}>
              {sumTotal(reportData)}
            </Text>
          </View>
        </View>
        <Image src={chartAsImage(chartRef)} style={styles.chartImage} />
      </View>
    </Page>
  </Document>
);

export const sumTotalPrices = (orders: any) => {
  const total = orders.reduce((acc: any, order: any) => {
    const price = order.totalPrice !== null ? parseFloat(order.totalPrice) : 0;
    return acc + price;
  }, 0);

  return parseCurrency(total);
};

export const sumTotal = (orders: any) => {
  return orders.reduce((acc: any, order: any) => {
    const count = order.roomCount !== null ? parseInt(order.roomCount) : 0;
    return acc + count;
  }, 0);
};

export const parseCurrency = (value: string = "0") => {
  if (value === null) return "L 0.00";
  const number = parseFloat(value);
  return number.toLocaleString("es-HN", {
    style: "currency",
    currency: "HNL",
    minimumFractionDigits: 2,
  });
};

export const getFieldValueByPaymentMethod = (
  data: any,
  paymentMethodType: string
) => {
  const method = data?.find((x: any) => x.paymentMethod === paymentMethodType);
  return method ? method.totalPrice : 0.0;
};

export const getFieldValueByRoom = (data: any, roomName: string) => {
  const room = data?.find((x: any) => x.roomName === roomName);
  return room ? room.totalPrice : 0.0;
};

export const getFieldValueByRoomType = (data: any, roomType: string) => {
  const type = data?.find((x: any) => x.roomType === roomType);
  return type ? type.totalPrice : 0.0;
};

export const getFieldValueByRoomTypeQuantity = (data: any, roomType: string) => {
  const type = data?.find((x: any) => x.roomType === roomType);
  return type ? type.roomCount : 0;
};

export const getSalesChartByPaymentMethodData = (data: any) => {
  return {
    labels: [
      paymentMethod.CASH,
      paymentMethod.TRANSFER,
      paymentMethod.CREDIT_CARD,
      paymentMethod.CREDIT,
      paymentMethod.PAYMENT_LINK,
    ],
    datasets: [
      {
        borderWidth: 1,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        hoverBackgroundColor: "rgba(255, 99, 132, 0.4)",
        data: [
          getFieldValueByPaymentMethod(data, paymentMethod.CASH),
          getFieldValueByPaymentMethod(data, paymentMethod.TRANSFER),
          getFieldValueByPaymentMethod(data, paymentMethod.CREDIT_CARD),
          getFieldValueByPaymentMethod(data, paymentMethod.CREDIT),
          getFieldValueByPaymentMethod(data, paymentMethod.PAYMENT_LINK),
        ],
      },
    ],
  };
};

export const getSalesChartByRoomsData = (rooms: IRoom[], data: any) => {
  const roomsNames = rooms.map((x) => x.name);
  const orderedData = orderAlphaNumeric(data, "roomName");
  const chartData = roomsNames.map((x: any) =>
    getFieldValueByRoom(orderedData, x)
  );
  return {
    labels: roomsNames,
    datasets: [
      {
        borderWidth: 1,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        hoverBackgroundColor: "rgba(255, 99, 132, 0.4)",
        data: chartData,
      },
    ],
  };
};

export const getSalesChartByRoomTypesData = (data: any) => {
  return {
    labels: [
      roomTypes.SINGLE,
      roomTypes.DOUBLE,
      roomTypes.TRIPLE,
      roomTypes.JRSUITE,
      roomTypes.SUITE,
    ],
    datasets: [
      {
        borderWidth: 1,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        hoverBackgroundColor: "rgba(255, 99, 132, 0.4)",
        data: [
          getFieldValueByRoomType(data, roomTypes.SINGLE),
          getFieldValueByRoomType(data, roomTypes.DOUBLE),
          getFieldValueByRoomType(data, roomTypes.TRIPLE),
          getFieldValueByRoomType(data, roomTypes.JRSUITE),
          getFieldValueByRoomType(data, roomTypes.SUITE),
        ],
      },
    ],
  };
};

export const getChartByRoomTypeQuantityData = (data: any) => {
  return {
    labels: [
      roomTypes.SINGLE,
      roomTypes.DOUBLE,
      roomTypes.TRIPLE,
      roomTypes.JRSUITE,
      roomTypes.SUITE,
    ],
    datasets: [
      {
        borderWidth: 1,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        hoverBackgroundColor: "rgba(255, 99, 132, 0.4)",
        data: [
          getFieldValueByRoomTypeQuantity(data, roomTypes.SINGLE),
          getFieldValueByRoomTypeQuantity(data, roomTypes.DOUBLE),
          getFieldValueByRoomTypeQuantity(data, roomTypes.TRIPLE),
          getFieldValueByRoomTypeQuantity(data, roomTypes.JRSUITE),
          getFieldValueByRoomTypeQuantity(data, roomTypes.SUITE),
        ],
      },
    ],
  };
};

export const getDetailsComponent = (filteredDate: any, reportData: any) => (
  <div style={{ paddingLeft: 50, marginBottom: 50 }}>
    <Card style={{ borderWidth: 0 }}>
      <Card.Grid className="table-row-detail" style={gridStyle}>
        <b>Fecha de reporte</b>
        <br />
        <span>{getTodayShort()}</span>
      </Card.Grid>
      <Card.Grid className="table-row-detail" style={gridStyle}>
        <b>Fecha inicial</b>
        <br />
        <span>{getShortDateString(filteredDate[0])}</span>
      </Card.Grid>
      <Card.Grid className="table-row-detail" style={gridStyle}>
        <b>Fecha final</b>
        <br />
        <span>{getShortDateString(filteredDate[1])}</span>
      </Card.Grid>
      <Card.Grid className="table-row-detail" style={gridStyle}>
        <b>Total</b>
        <br />
        <span>L. {sumTotalPrices(reportData)}</span>
      </Card.Grid>
    </Card>
  </div>
);


export const getDetailsQuantityComponent = (filteredDate: any, reportData: any) => (
  <div style={{ paddingLeft: 50, marginBottom: 50 }}>
    <Card style={{ borderWidth: 0 }}>
      <Card.Grid className="table-row-detail" style={gridStyle}>
        <b>Fecha de reporte</b>
        <br />
        <span>{getTodayShort()}</span>
      </Card.Grid>
      <Card.Grid className="table-row-detail" style={gridStyle}>
        <b>Fecha inicial</b>
        <br />
        <span>{getShortDateString(filteredDate[0])}</span>
      </Card.Grid>
      <Card.Grid className="table-row-detail" style={gridStyle}>
        <b>Fecha final</b>
        <br />
        <span>{getShortDateString(filteredDate[1])}</span>
      </Card.Grid>
      <Card.Grid className="table-row-detail" style={gridStyle}>
        <b>Total</b>
        <br />
        <span>{sumTotal(reportData)}</span>
      </Card.Grid>
    </Card>
  </div>
);

export const gridStyle = {
  width: "25%",
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 20,
    marginBottom: 10,
  },
  chartImage: {
    width: 500,
    height: 300,
  },
  table: {
    width: "100%",
    flexDirection: "row",
  },
  cell: {
    flexGrow: 1,
    padding: 10,
    borderWidth: 0,
  },
  title: {
    fontSize: 12,
    paddingBottom: 6,
    textAlign: "center",
    textDecoration: "underline",
  },
  subtitle: {
    fontSize: 10,
    paddingBottom: 6,
    textAlign: "center",
  },
});
