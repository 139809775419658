import axios from "axios";
import IRoom from "@interfaces/IRoom";
import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/room";

export const getRoom = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching room:", error);
    throw error;
  }
};

export const getRooms = async () => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/all`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching rooms:", error);
    throw error;
  }
};

export const createRoom = async (roomData: IRoom) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      roomData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error creating room:", error);
    throw error;
  }
};

export const updateRoom = async (roomData: IRoom) => {
  try {
    const { id } = roomData;
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/${id}`,
      roomData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error updating room:", error);
    throw error;
  }
};

export const deleteRoom = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error deleting room:", error);
    throw error;
  }
};
