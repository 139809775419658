import { ROLE } from "@constants/role";
import { getAuth } from "firebase/auth";

export const isUserAdmin = async () => {
  const user = getAuth().currentUser;
  if (user) {
    const profile = await user.getIdTokenResult();
    return profile.claims.role === ROLE.ADMIN;
  }
  return false;
};
